import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ApiHandler } from '../../../helper/ApiHandler';
import { OurPlatformData } from '../../../redux/slice/authSlice';
import { toast } from 'react-toastify';
import { slugify } from '../../../../src/utils/slugify';

const MainContent = () => {
    const [categories, setCategories] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [selectedPlatform, setSelectedPlatform] = useState('all');
    const [categoryMap, setCategoryMap] = useState({});
    const [platformMap, setPlatformMap] = useState({});

    const ourPlatform = useSelector((state) => state.auth.ourPlatform);
    const token = useSelector((state) => state.auth.token);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    // Fetch platform and category data on component mount
    useEffect(() => {
        const fetchPlatforms = async () => {
            try {
                const response = await ApiHandler('/get_game_list.php', 'POST', { gameID: "", filter: 'latest' }, undefined, dispatch, navigate);
                if (response.data.status.code === "1") {
                    dispatch(OurPlatformData({ ourPlatform: response.data.data }));
                }
            } catch (error) {
                console.error('Error fetching platforms:', error);
            }
        };

        fetchPlatforms();
    }, [dispatch, navigate]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await ApiHandler('/get_categorises_list.php', 'GET', undefined, token, dispatch, navigate);
                if (response.status === 200) {
                    setCategories(response.data.data);

                    // Create a mapping of category names to IDs
                    const map = {};
                    response.data.data.forEach((category) => {
                        map[category.cat_name.toLowerCase()] = category.id;
                    });
                    setCategoryMap(map);
                } else {
                    toast.error("Error fetching categories.");
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, [dispatch, navigate]);

    useEffect(() => {
        const fetchPlatformsList = async () => {
            try {
                const response = await ApiHandler('/get_platforms_list.php', 'GET', undefined, token, dispatch, navigate);
                if (response.status === 200) {
                    setPlatforms(response.data.data);

                    // Create a mapping of platform names to IDs
                    const map = {};
                    response.data.data.forEach((platform) => {
                        map[platform.name.toLowerCase()] = platform.id;
                    });
                    setPlatformMap(map);
                } else {
                    toast.error("Error fetching platforms.");
                }
            } catch (error) {
                console.error('Error fetching platforms:', error);
            }
        };

        fetchPlatformsList();
    }, [dispatch, navigate]);

    // Initialize selected filters from URL parameters
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const category = (queryParams.get('category') || 'all').toLowerCase();
        const platform = (queryParams.get('platform') || 'all').toLowerCase();
        setSelectedCategory(category);
        setSelectedPlatform(platform);
    }, [location]);

    const handleCategoryChange = (e) => {
        const category = e.target.value;
        setSelectedCategory(category);
        navigate(`/games?category=${category}&platform=${selectedPlatform}`);
    };

    const handlePlatformChange = (e) => {
        const platform = e.target.value;
        setSelectedPlatform(platform);
        navigate(`/games?category=${selectedCategory}&platform=${platform}`);
    };

    // Filter games based on category and platform
    const filteredGames = ourPlatform?.filter((platform) => {
        const categoryMatch = selectedCategory === 'all' || platform.cat_id === categoryMap[selectedCategory.toLowerCase()];
        const platformMatch = selectedPlatform === 'all' || platform.platforms_id === platformMap[selectedPlatform.toLowerCase()];
        return categoryMatch && platformMatch;
    });

    return (
        <div className='container mx-auto text-white my-10'>
            <div className="mb-6 flex space-x-4">
                <div>
                    <label htmlFor="category-select" className="mr-2">Filter by Category:</label>
                    <select
                        id="category-select"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        className="bg-[#FFDD15] text-black font-bold p-2 rounded-lg"
                    >
                        <option value="all">All Categories</option>
                        {categories.map((category) => (
                            <option key={category.id} value={category.cat_name.toLowerCase()}>
                                {category.cat_name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="platform-select" className="mr-2">Filter by Platform:</label>
                    <select
                        id="platform-select"
                        value={selectedPlatform}
                        onChange={handlePlatformChange}
                        className="bg-[#FFDD15] text-black font-bold p-2 rounded-lg"
                    >
                        <option value="all">All Platforms</option>
                        {platforms.map((platform) => (
                            <option key={platform.id} value={platform.name.toLowerCase()}>
                                {platform.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4'>
                {filteredGames?.length > 0 ? (
                    filteredGames.map((game) => (
                        <div key={game.id} className='flex justify-center'>
                            <Link to={`/games/description/${slugify(game.game_name || '')}`}>
                                <img
                                    src={game.game_image}
                                    alt={game.game_name}
                                    className="w-full h-[200px] object-cover border-2 border-[#FFDD15] rounded-lg"
                                />
                            </Link>
                        </div>
                    ))
                ) : (
                    <div className='col-span-2 sm:col-span-3 md:col-span-4 lg:col-span-5 text-center text-lg'>
                        No games available for this category and platform.
                    </div>
                )}
            </div>
        </div>
    );
};

export default MainContent;
