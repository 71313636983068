import React from 'react'
import Terms from '../assets/image/blogBg.png'
import Hero from '../components/Hero'
import MainContent from '../components/TermsService/MainContent'

const TermsService = () => {
  return (
    <>
       <Hero bgImg={Terms} title={"Terms Of Use"} />
       <MainContent />
    </>
  )
}

export default TermsService
