import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout, setLevelDataState, UPDATE_BALANCE, userData, userToken } from '../../../redux/slice/authSlice';
import SignUpBg from '../../../assets/image/signUpBg.png';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiHandler } from '../../../helper/ApiHandler';
import { resetGamesState } from '../../../redux/slice/gamesSlice';
import Loading from '../../../components/Common/Loading';
import { clearCouponCode } from '../../../redux/slice/couponSlice';

const Login = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [dispatchLoading, setDispatchLoading] = useState(true); // Default loading state is true
    const [loading, setLoading] = useState(false); // Default loading state is false
    const dispatch = useDispatch();
    const navigate = useNavigate();

       // Reset Redux state when the component is first loaded
       useEffect(() => {
        const resetState = async () => {
            // Set loading to true at the start
            setDispatchLoading(true);
            // Reset games slice && Reser auth slice (or any other slice if needed)
            dispatch(resetGamesState());
            dispatch(clearCouponCode());
            dispatch(logout());

            // Set loading to false once state reset is complete
            setDispatchLoading(false);
        };

        resetState();
    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            const response = await ApiHandler('/login.php', 'POST', formData, undefined, dispatch, navigate);
            if (response.data.status.code === 1) {
                toast.success(response.data.status.message);
                const { token } = response.data.data;

                dispatch(userToken({ token }));

                // Fetch bounus level data
                try {
                    const response = await ApiHandler('/bounus_level.php', 'POST', undefined, token, dispatch, navigate);
                    if (response.data && response.data.status.code === 1) {
                        const enrichedData = response.data.data; // Assume enrichData is a function to process data
                        dispatch(setLevelDataState(enrichedData));
                    } else {
                        console.error('Failed to fetch bonus levels: Invalid response');
                    }
                } catch (error) {
                    console.error('Failed to fetch bonus levels:', error);
                }

                // Fetch user profile data
                const profileResponse = await ApiHandler('/get_profile.php', 'GET', undefined, token, dispatch, navigate);

                if (profileResponse.data.status.code === 1) {
                    dispatch(userData({ user: profileResponse.data.data }));
                    navigate('/bonuses/level');
                    setFormData({
                        ...formData,
                        email: '',
                        password: '',
                    });
                } else {
                    toast.error('Failed to fetch profile data');
                }

                // Get User Balacnce
                try {
                    const response = await ApiHandler('/user_balance.php', 'GET', undefined, token, dispatch, navigate);

                    const { main_balance, bonus_balance, total_deposit, promotion_count, system_count, total_count } = response.data.data;

                    // Dispatch the action to update the balance
                    dispatch(UPDATE_BALANCE({ main_balance, bonus_balance, total_deposit, promotion_count, system_count, total_count }));
                } catch (error) {
                    console.log(error);
                }

                setLoading(false);
            } else {
                toast.error(response.data.status.message);
                setFormData({
                    ...formData,
                    email: '',
                    password: '',
                });
                setLoading(false);
            }

        } catch (error) {
            console.error('Login error:', error);
            toast.error('Login failed. Please check your credentials and try again.');
            setLoading(false);
        }
    };

    if (dispatchLoading) {
        return <Loading />;
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <div
            style={{
                backgroundImage: `url(${SignUpBg})`,
            }}
            className="min-h-screen bg-cover"
        >
            <div className="container mx-auto px-4 md:px-8">
                <div className="pt-16 md:pt-32">
                    <h1 className="flex font-bold justify-center items-center text-[#FFDD15] text-2xl md:text-4xl underline bg-cover">
                        LOGIN
                    </h1>
                </div>

                <div className="flex justify-center mt-8 md:mt-10 pb-10">
                    <form onSubmit={handleSubmit} className="bg-[#0E0E0E] p-4 md:p-6 rounded-xl w-full md:w-2/3 lg:w-1/2">
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-white text-sm mb-2">
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full p-2 rounded-md bg-[#222222] text-white border border-white/50"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <div className='flex justify-between'>
                                <label htmlFor="password" className="block text-white text-sm mb-2">
                                    Password
                                </label>
                                <Link to="/forgot-password" className="text-yellow-500 text-sm ml-4 hover:underline">
                                    Forgot Password?
                                </Link>
                            </div>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                className="w-full p-2 rounded-md bg-[#222222] text-white border border-white/50"
                                required
                            />
                        </div>
                        <button type="submit" className="w-full mt-4 bg-yellow-500 text-black py-2 rounded-md font-semibold">
                            Sign In
                        </button>
                        <div className="flex justify-center items-center mt-4 text-sm text-white">
                            <p>Do not have an account?</p>
                            <Link to="/sign-up" className="ml-2 text-yellow-500 hover:underline">
                                Register Now
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
