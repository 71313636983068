import React from 'react'
import Hero from '../../../components/Hero'
import AboutBg from '../../../assets/image/blogBg.png'
import Aboutus from '../../../components/about'

const About = () => {
    return (
        <>
            <Hero title={"About"} bgImg={AboutBg} backgroundColor='#290A47' />
            <Aboutus />
        </>
    )
}

export default About
