import React from 'react';
import Logo from "../../assets/image/luckcharmsweep.png";

const AboutUs = () => {
    const platforms = [
        { name: "Orion Stars", link: "/orion-stars" },
        { name: "Fire Kirin", link: "/fire-kirin" },
        { name: "Game Vault", link: "/game-vault" },
        { name: "Juwa", link: "/juwa" },
        { name: "Milky Way", link: "/milky-way" },
        { name: "Para Casino", link: "/para-casino" },
        { name: "Riversweeps", link: "/riversweeps" },
        { name: "Mafia", link: "/mafia" },
        { name: "Cash Machine", link: "/cash-machine" },
        { name: "Game Room", link: "/game-room" },
        { name: "Golden Treasure", link: "/golden-treasure" },
        { name: "Blue Dragon", link: "/blue-dragon" },
        { name: "V-blink", link: "/v-blink" },
        { name: "Ulta Panda", link: "/ulta-panda" },
        { name: "Panda Master", link: "/panda-master" },
        { name: "Lucky Star", link: "/lucky-star" },
        { name: "Moolah", link: "/moolah" },
        { name: "E-Games", link: "/e-games" },
        { name: "Cash Frenzy", link: "/cash-frenzy" },
        { name: "Joker", link: "/joker" }
    ];

    return (
        <div className="flex flex-col md:flex-row items-center bg-[#290A47] p-8 rounded-lg text-white container mx-auto border-[3px] border-[#EC29FC]">
            <img src={Logo} alt="logo" className='w-[200px] md:w-[350px] mb-4 md:mb-0' />



            <div className="md:ml-8 text-center md:text-left">
                <h1 className="text-2xl md:text-3xl font-bold">
                    Lucky Charm Sweep – The Best Online Sweepstakes for Fun and Wins!
                </h1>
                <p className="mt-4 text-base md:text-lg text-[#CACACA]">
                Lucky Charm Sweep brings you the best slot games for a truly memorable experience. Enjoy the latest online slots delivered right to your fingertips, with exceptional gameplay anytime, anywhere! Get paid fast and hassle-free with instant, seamless withdrawals!                </p>
                
                <div className="mt-6 text-sm text-[#CACACA]">
                    <p>Explore our game platforms:</p>
                    <ul className="flex flex-wrap gap-2 mt-2">
                        {platforms.map(platform => (
                            <li key={platform.name}>
                                <a 
                                    href={`/platform/description${platform.link}`} // Fixed string interpolation
                                    className="hover:text-[#EC29FC] transition-colors"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {platform.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
