import React from 'react'
import Privacy from '../assets/image/blogBg.png'
import Hero from '../components/Hero'
import MainContent from '../components/PrivacyPolicy/MainContent'

const PrivacyPolicy = () => {
  return (
    <>
       <Hero bgImg={Privacy} title={"Privacy Policy"} />
       <MainContent />
    </>
  )
}

export default PrivacyPolicy
