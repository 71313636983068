import React, { useState } from 'react'
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckoutNotFound from '../../../components/Checkout/CheckoutNotFound';
import CheckOutMain from '../../../components/Checkout/CheckOutMain';
import { useSelector } from 'react-redux';
import Loading from '../../../components/Common/Loading';


const CheckOut = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const cartData = useSelector((state) => state.games.cart);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (!location.state?.isCheckoutAllowed) {
      // If no state or invalid state, redirect to the home page
      navigate('/cart');
    } else {
      const currentState = window.history.state;
      const updatedState = {
        ...currentState,
        usr: {
          ...currentState.usr,
          isCheckoutAllowed: false
        }
      };
      
      // Replace the current history state with the updated state
      window.history.replaceState(updatedState, document.title);
    }
  }, [location, navigate]);

  if (!token) {
    navigate('/login');
    return;
  }

  if (!cartData || cartData.length === 0) {
    return (
      <CheckoutNotFound />
    );
  }

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <div className='container mx-auto py-12 lgs:pt-40 lgs:pb-12 min-h-[70vh] px-0'>
        <CheckOutMain cartData={cartData} userData={userData}/>
      </div>
    </>
  )
}

export default CheckOut
