import React, { useEffect, useState } from 'react';
import Loading from '../../../components/Common/Loading';
import { useSelector } from 'react-redux';
import CartNotFound from '../../../components/Cart/CartNotFound';
import CartMain from '../../../components/Cart/CartMain';
import { useNavigate } from 'react-router-dom';

const Cart = () => {
    const cartData = useSelector((state) => state.games.cart);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth.token);

    if (!token) {
        navigate('/login');
        return;
    }

    if (!cartData || cartData.length === 0) {
        return (
            <CartNotFound />
        );
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <div className='container mx-auto py-12 lgs:pt-40 lgs:pb-12 min-h-[70vh]'>
                <CartMain cartData={cartData} />
            </div>
        </>
    );
}

export default Cart;
