import React, { useEffect, useState } from 'react';
import Hero from '../../../components/Hero';
import GamesDescriptionBg from '../../../assets/image/gamesDescriptionBg.png';
import MilkyWayCasino from '../../../components/gamesDescription/MilkyWayCasino';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApiHandler } from '../../../helper/ApiHandler';
import Loading from '../../../components/Common/Loading';

const PlatformDescription = () => {
    const { platform_slug } = useParams();
    const [gameDetails, setGameDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!platform_slug) {
            console.error('Game slug is undefined');
            return;
        }

        const fetchGameDetails = async () => {
            setLoading(true);
            try {
                const response = await ApiHandler(
                    '/get_game_list.php',
                    'POST',
                    { platform_slug: platform_slug, is_platform: "1" },
                    undefined,
                    dispatch,
                    navigate
                );
                const gameData = response.data?.data?.[0];
                if (gameData) {
                    if (!gameData.game_description) {
                        // If game_description is empty, redirect to /deposit
                        navigate('/deposit');
                    } else {
                        setGameDetails(gameData);
                    }
                } else {
                    console.error('Platform details not found for platform slug:', platform_slug);
                }
            } catch (error) {
                console.error('Failed to fetch game details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchGameDetails();
    }, [platform_slug, dispatch, navigate]);

    if (loading) {
        return <Loading />;
    }

    // Function to select the appropriate game component based on platform_slug
    const renderGameComponent = () => {
        switch (platform_slug) {
            case 'milky-way':
                return <MilkyWayCasino gameDetails={gameDetails} />;
           
            // Add more cases for other game components
            default:
                return <p>Game not found.</p>;
        }
    };

    return (
        <>
            <Hero
                title={gameDetails?.platform_name || 'Game Not Found'}
                bgImg={GamesDescriptionBg}
                backgroundColor='#290A47'
            />
            {gameDetails ? renderGameComponent() : <p>Game not found.</p>}
        </>
    );
};

export default PlatformDescription;
