import React, { useState } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const PaypalCheckoutButton = ({ product, onSuccess, onError }) => {
  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate

  const paypalFeePercentage = 0.029; // 2.9%
  const paypalFixedFee = 0.30; // $0.30

  const calculateFees = (amount) => {
    const fees = amount * paypalFeePercentage + paypalFixedFee;
    const amountReceived = amount - fees;
    return { fees, amountReceived };
  };

  const handleApprove = async (orderId) => {
    try {
      setPaidFor(true);
      const { fees, amountReceived } = calculateFees(product.price);

      // Call the onSuccess prop with additional data if needed
      onSuccess(orderId, fees, amountReceived);

      // Display success toast message
      toast.success(`Thank you for your purchase!`);

      // Redirect to /dashboard
      navigate("/dashboard");
    } catch (err) {
      // Handle any errors and show an error toast
      setError(err);
      toast.error("An error occurred. Please try again.");
    }
  };
 
  if (paidFor) {
    return <div>Thank you for your purchase!</div>;
  }
 

  return (
    <div>
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: product.description,
                amount: {
                  currency_code: "USD",
                  value: product.price.toFixed(2), // Ensure this is a string
                },
              },
            ],
            application_context: {
              shipping_preference: "NO_SHIPPING", // Disable shipping address collection
            },
          });
        }}
        onApprove={async (data, actions) => {
          try {
            const order = await actions.order.capture();
            handleApprove(data.orderID); // Handle success
          } catch (err) {
            console.error("Payment capture failed", err);
            toast.error("Payment capture failed. Please try again."); // Show error toast
          }
        }}
        onError={(err) => {
          setError(err);
          console.error("PayPal Checkout onError", err);
          toast.error("Payment failed. Please try again."); // Show error toast
        }}
        style={{
          color: "gold", // PayPal button color
          layout: "vertical", // Vertical layout for buttons
          label: "paypal", // Label for the button
        }}
      />

      {error && <div>Error: {error.message}</div>}
    </div>
  );
};

export default PaypalCheckoutButton;
