import React from 'react'
import { Link } from 'react-router-dom'

const Blogmain = ({
  categories,
  blogsDetails
}) => {
  return (
    <>
      {blogsDetails && (
        <div className='flex justify-between items-start flex-col md:flex-row relative blogdetails mt-10 md:mt-20'>
          <div className='w-full md:w-[65%] lg:w-[70%] md:pr-8'>
            <div
              className="prose prose-lg prose-invert max-w-none d_html"
              dangerouslySetInnerHTML={{ __html: blogsDetails?.description }}
            />
          </div>
          <div className='space-y-3 p-6 w-full md:w-[35%] lg:w-[30%] sticky top-10 right-0'
            style={{
              background: '#1a1f2c',
              borderRadius: '1rem'
            }}>
            <h1 className='text-xl font-bold'>Categories</h1>
            <ul role="list" className="space-y-1 capitalize list-disc px-8">
              {categories.map((category) => (
                <li key={category.id}>
                  <Link to={`/blog/category/${category.id}`} className="chakra-link css-1ynruec">{category.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

export default Blogmain
