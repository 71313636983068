import React, { useEffect, useState, useRef } from 'react';
import Title from '../Title';
import { getImageUrl } from '../../utils/getImageUrl'; // Use helper function for background image
import VerifiedIcon from '../../assets/image/Verified.gif';
import StarRatings from 'react-star-ratings';
import Slider from 'react-slick';
import TestimonialAvatar from '../../assets/image/testimonialAvatar.png'; // Import default avatar image

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Testimonial Item Component for Reusability
const TestimonialItem = ({ name, title, rating, reviewText, avatarUrl, createdAt }) => {
    const validRating = typeof rating === 'number' ? rating : parseFloat(rating);
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    });

    return (
        <div className="flex justify-center items-center my-20 flex-col md:flex-row">
            <div className="border border-[#3C3C3C] bg-black relative flex flex-col justify-between items-center w-[305px] rounded-md px-10 py-5 min-h-[250px] max-h-[350px]">
                <img
                    className="absolute w-[100px] h-[100px] rounded-full object-cover -top-16"
                    src={avatarUrl || TestimonialAvatar} // Use default avatar if avatarUrl is not available
                    alt="testimonialAvatar"
                    loading="lazy" // Add lazy loading
                />
                <div className="flex flex-col items-center">
                    <p className="mt-8 text-[#FFDD15] font-bold text-lg flex items-center">
                        {name}
                        <img src={VerifiedIcon} alt="Verified" className="ml-2 w-5 h-5" />
                    </p>
                    <p className="text-[#4D4C44] font-bold">{title}</p>
                    <p className="text-white text-center text-sm leading-4 mt-1">{reviewText}</p>
                    <p className="text-gray-400 text-sm mt-2">Reviewed on {formattedDate}</p>
                </div>
                <div className="flex items-center justify-center gap-2 pt-5 pb-5">
                    <StarRatings
                        rating={validRating}
                        starRatedColor="gold"
                        numberOfStars={5}
                        starDimension="20px"
                        starSpacing="5px"
                        name="rating"
                    />
                    <span className="text-white mt-1">{validRating.toFixed(1)}/5</span>
                </div>
            </div>
        </div>
    );
};

const Testimonials = () => {
    const [testimonials, setTestimonials] = useState([]);
    const [isBgLoaded, setIsBgLoaded] = useState(false); // State for background image loading
    const divRef = useRef(null); // Ref for lazy loading the background image

    useEffect(() => {
        fetch('https://api.luckycharmsweep.com/api/reviews.php')
            .then(response => response.json())
            .then(data => {
                setTestimonials(data);
            })
            .catch(error => console.error('Error fetching reviews:', error));
    }, []);

    // Use IntersectionObserver to implement lazy loading for the background image
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setIsBgLoaded(true); // Load background image when in view
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );

        if (divRef.current) {
            observer.observe(divRef.current);
        }

        return () => {
            if (divRef.current) {
                observer.disconnect();
            }
        };
    }, []);

    const backgroundImageUrl = getImageUrl('/assets/image/testimonials.png'); // Use helper function

    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div
            ref={divRef} // Attach ref for lazy loading
            className="mt-16 pb-1"
            style={{
                backgroundImage: isBgLoaded ? `url(${backgroundImageUrl})` : 'none', // Load background image when in view
                backgroundSize: 'cover',
            }}
        >
            <div className="container mx-auto">
                <Title title="A GAMBLE WORTH TALKING, ACCORDING TO OUR" continueTitle="SATISFIED PLAYERS" br={true} />
                <div className="my-20">
                    <Slider {...settings}>
                        {testimonials.length > 0 ? (
                            testimonials.map((testimonial, index) => (
                                <TestimonialItem
                                    key={index}
                                    name={testimonial.name}
                                    title={
                                        <span style={{ color: '#B0B0B0' }}>{testimonial.title}</span> // Apply inline style
                                    }                                  
                                      rating={testimonial.rating}
                                    reviewText={testimonial.review_text}
                                    avatarUrl={testimonial.avatar_url || TestimonialAvatar} // Use avatar_url from API or default image
                                    createdAt={testimonial.created_at} // Pass created_at date from API
                                />
                            ))
                        ) : (
                            <p>Loading reviews...</p>
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
