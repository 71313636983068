import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiHandler } from "../../helper/ApiHandler";
import { toast } from "react-toastify";
import { setCouponCode } from "../../redux/slice/couponSlice";
import SpinnerAudio from "../../assets/audiomass-output.mp3";
import circle from "../../assets/circle.png";


const WheelComponent = ({
  segments,
  segColors,
  winningSegment,
  onFinished,
  onRotate,
  primaryColor = "#000", // Default primary color
  primaryColoraround = "#fff", // Default outer circle color
  contrastColor = "#fff", // Default contrast color
  buttonText = "Spin", // Default button text
  isOnlyOnce = true,
  size = 180, // Adjusted spinner size for mobile
  upDuration = 50,
  downDuration = 650,
  fontFamily = "Arial",
  setShowPopup = false,
}) => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let currentSegment = "";
  let isStarted = false;
  const [isFinished, setFinished] = useState(false);
  const [audio, setAudio] = useState(null);

  let timerHandle = 0;
  const timerDelay = segments.length;
  let angleCurrent = 0;
  let angleDelta = 0;
  let canvasContext = null;
  let maxSpeed = Math.PI / segments.length;
  const upTime = segments.length * upDuration;
  const downTime = segments.length * downDuration;
  let spinStart = 0;
  let frames = 0;
  const centerX = size;
  const centerY = size;

  useEffect(() => {
    wheelInit();
  }, []);

  useEffect(() => {
    if (isFinished && audio) {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [isFinished]);

  const wheelInit = () => {
    initCanvas();
    wheelDraw();
  };

  const initCanvas = () => {
    let canvas = document.getElementById("canvas");
    canvas.addEventListener("click", spin, false);
    canvasContext = canvas.getContext("2d");
  };

  const spin = async () => {
    // First, make the API call to check if the user can spin
    const canSpin = await checkSpinAPI();
    if (canSpin.message) {
      toast.error("No Spins Left Minimum 10$ deposit in 7 days requires to spin the wheel");
      return;
    } else if (!canSpin.spin) {
      setShowPopup(true);
      return;
    }

    isStarted = true;
    if (isStarted) {
      const spinnerSound = new Audio(SpinnerAudio);
      spinnerSound.loop = false;
      spinnerSound.play();
      setAudio(spinnerSound);
    }


    onRotate && onRotate();
    if (timerHandle === 0) {
      spinStart = new Date().getTime();
      maxSpeed = Math.PI / segments.length;
      frames = 0;
      timerHandle = setInterval(onTimerTick, timerDelay);
    }
  };

  const checkSpinAPI = async () => {
    try {
      const response = await ApiHandler('/check_spin.php', 'GET', undefined, token, dispatch, navigate);
      if (response?.data?.status.code === 1) {
        const data = response.data.data;
        return data;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking spin:", error);
      return false;
    }
  };


  const onTimerTick = async () => {
    frames++;
    draw();
    const duration = new Date().getTime() - spinStart;
    let progress = 0;
    let finished = false;
    if (duration < upTime) {
      progress = duration / upTime;
      angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2);
    } else {
      progress = duration / downTime;
      angleDelta =
        maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
      if (progress >= 1) finished = true;
    }

    angleCurrent += angleDelta;
    while (angleCurrent >= Math.PI * 2) angleCurrent -= Math.PI * 2;
    if (finished) {
      setFinished(true);
      onFinished && onFinished(currentSegment);
      clearInterval(timerHandle);
      timerHandle = 0;
      angleDelta = 0;

      // Call the second API after the spin
      await handleSpinResult(currentSegment);
    }
  };

  const handleSpinResult = async (result) => {
    let body = {};
    const expiryTime = Date.now() + 24 * 60 * 60 * 1000; // Set expiry time to 24 hours from now


    // Check if the result contains a Free Spin
    if (result.includes("Free Spin")) {
      body = { bounes: "", couponcode: "", discountPercentage: "", free_spin: true };
    } else {
      const value = result.replace("$", ""); // Remove the dollar sign if present

      // Check if the result includes a percentage (indicating a discount)
      if (value.includes("%")) {
        const valuePercentage = value.replace("%", ""); // Remove the dollar sign if present
        const couponCode = generateCouponCode(); // Generate the coupon code
        body = { bounes: "", couponcode: couponCode, discountPercentage: valuePercentage, free_spin: false };

        // Dispatch the coupon code and expiry time to Redux store
        dispatch(setCouponCode({ couponCode, expiryTime, valuePercentage }));
      } else {
        // Otherwise, treat it as a cash prize (or other bonus)
        body = { bounes: value, couponcode: "", discountPercentage: "", free_spin: false };
      }
    }

    // Now, submit the spin result
    await submitSpinResult(body);
  };

  const generateCouponCode = () => {
    return Math.random().toString(36).substring(2, 8).toUpperCase(); // Generates a 6-letter uppercase code
  };

  const submitSpinResult = async (body) => {
    setFinished(true);
    try {
      const response = await ApiHandler('/get_spin_data.php', 'POST', body, token, dispatch, navigate);
      if (response?.data?.status.code === 1) {
        if (body.bounes) {
          toast.success("Congratulations! You won " + body.bounes);
          navigate('/dashboard')
        } else if (body.free_spin) {
          toast.success("Congratulations! You won a Free Spin");
          navigate('/wheel')
        } else if (body.couponcode) {
          navigate('/wheel')
          toast.success("Congratulations! You won a coupon code discount: " + body.discountPercentage + "%");
        }
      } else {
        console.error("Error checking spin:");
      }
    } catch (error) {
      console.error("Error checking spin:", error);
    }
  };

  const wheelDraw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const draw = () => {
    clear();
    drawWheel();
    drawNeedle();
  };

  const drawSegment = (key, lastAngle, angle) => {
    const ctx = canvasContext;
    const value = segments[key];

    // Begin the path for the segment
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, size, lastAngle, angle, false);
    ctx.lineTo(centerX, centerY);
    ctx.closePath();

    // Extract the linear-gradient stops
    const gradientStr = segColors[key];
    const colorStops = gradientStr.match(/#[0-9A-Fa-f]{6}/g); // Extract hex codes
    const direction = parseFloat(gradientStr.match(/(\d+\.?\d*)deg/)[1]); // Extract the angle

    // Create a canvas gradient based on the extracted direction and color stops
    const gradient = ctx.createLinearGradient(
      centerX + size * Math.cos((direction * Math.PI) / 180), // Start X
      centerY + size * Math.sin((direction * Math.PI) / 180), // Start Y
      centerX - size * Math.cos((direction * Math.PI) / 180), // End X
      centerY - size * Math.sin((direction * Math.PI) / 180)  // End Y
    );

    // Add color stops to the gradient
    if (colorStops && colorStops.length >= 3) {
      gradient.addColorStop(0, colorStops[0]);  // First color stop
      gradient.addColorStop(0.2, colorStops[1]); // Middle color stop
      gradient.addColorStop(1, colorStops[2]);   // Last color stop
    }

    // Set the gradient as the fill style
    ctx.fillStyle = gradient;
    ctx.fill();
    ctx.stroke();

    // Draw the text on the segment
    ctx.save();
    ctx.translate(centerX, centerY);
    ctx.rotate((lastAngle + angle) / 2);
    // ctx.fillStyle = contrastColor || "white"; // Text color
    ctx.fillStyle = "#ebebeb"; // Text color
    const fontSize = size / 10 + "px";
    ctx.font = `bold ${fontSize} ${fontFamily}`;
    ctx.fillText(value.substr(0, 9), size / 1.6, 0);  // Adjust the text positioning
    ctx.restore();
  };

  const drawWheel = () => {
    const ctx = canvasContext;
    let lastAngle = angleCurrent;
    const len = segments.length;
    const PI2 = Math.PI * 2;
    ctx.lineWidth = 2; // Reduced line width
    ctx.strokeStyle = "white";//line
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";

    for (let i = 1; i <= len; i++) {
      const angle = PI2 * (i / len) + angleCurrent;
      drawSegment(i - 1, lastAngle, angle);
      lastAngle = angle;
    }

    // Draw center button (small and readable)
    // Draw the button
    ctx.beginPath();
    ctx.arc(centerX, centerY, 40, 0, Math.PI * 2, false); // Slightly smaller center button
    ctx.closePath();
    ctx.fillStyle = "#290A47"; // Button fill color
    ctx.lineWidth = 11;
    ctx.strokeStyle = "#FF9529"; // Border color (frame)
    ctx.fill();
    ctx.stroke();

    // Set text properties and draw "Spin" text
    ctx.font = `bold ${size / 10}px ${fontFamily}`;
    ctx.fillStyle = "white"; // Text color for "Spin"
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText("Spin", centerX, centerY); // Centered on the button

    // Draw the arrow (triangle pointing upwards)
    ctx.beginPath();
    ctx.moveTo(centerX, centerY - 60); // Top point of the arrow

    ctx.closePath();
    ctx.fillStyle = "#FF9529"; // Arrow fill color (matches text)
    ctx.fill();

    // Optionally outline the arrow with a border

    ctx.stroke();



  };

  const drawNeedle = () => {
    const ctx = canvasContext;
    ctx.lineWidth = 2;
    ctx.strokeStyle = contrastColor || "white";
    ctx.beginPath();
    ctx.moveTo(centerX + 8, centerY - 40);
    ctx.lineTo(centerX - 8, centerY - 40);
    ctx.lineTo(centerX, centerY - 60);
    ctx.closePath();
    ctx.fill();

    const change = angleCurrent + Math.PI / 2;
    let i = segments.length - Math.floor((change / (Math.PI * 2)) * segments.length) - 1;
    if (i < 0) i = i + segments.length;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "white";
    ctx.font = `bold ${size / 8}px ${fontFamily}`;
    currentSegment = segments[i];
    // ctx.fillText(currentSegment, centerX, centerY + size + 50);
  };

  const clear = () => {
    const ctx = canvasContext;
    ctx.clearRect(0, 0, size * 2, size * 2);
  };

  return (
    <>
      <div className="relative">
        <img src={circle} alt="" width={380} height={370} className="absolute sm:relative" />
        <div
          id="wheel"
          className="relative sm:absolute top-5 left-7 xs:top-7 xs:left-8 sm:top-6 sm:left-6"
          style={{
            padding: "5px", // Reduced padding for small screens
            display: "flex",
            justifyContent: "center",
            width: "100%", // Ensure the wheel takes the full width of the screen
            maxWidth: "370px", // Cap the max width to fit a typical mobile screen size
          }}
        >
          <canvas
            id="canvas"
            width={350} // Adjusted width to fit mobile screens better
            height={400} // Set height to maintain the aspect ratio
            style={{
              pointerEvents: isFinished && isOnlyOnce ? "none" : "auto",
              maxWidth: "100%", // Make sure the canvas adjusts its size responsively
              height: "auto", // Adjust height responsively to fit small screens
            }}
          />
        </div>
      </div>
    </>
  );
};

export default WheelComponent;
