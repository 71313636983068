import React from 'react'
import { DateIcon, UserIcon } from '../../utils/Icons';
import moment from 'moment/moment';

const BlogHead = ({ blogsDetails }) => {
  return (
    <>
      {blogsDetails && (
        <div className='w-full flex flex-col lg:flex-row justify-between items-center gap-16 lg:gap-0'>
          <div className='w-full lg:w-[40%]'>
            <div className='flex flex-col'>
              <div className='text-3xl xs:text-4xl md:text-5xl font-bold text-white'>{blogsDetails?.title}</div>
              <div className='flex justify-start gap-6 items-center mt-9'>
                <div className='flex justify-between items-center gap-4'>
                  <UserIcon />
                  <p className='text-[#CACACA] text-sm xs:text-base font-medium'>By {blogsDetails?.author}</p>
                </div>
                <div className='flex justify-center items-center gap-4'>
                  <DateIcon />
                  <p className='text-[#CACACA] text-sm xs:text-base font-medium'>
                    {moment(blogsDetails.created_date).format('YYYY-MM-DD')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full lg:w-[60%] text-end flex lg:justify-end items-center'>
            <div className='flex flex-col gap-3 justify-center items-center'>
              <div className='w-[200px] h-auto sm:w-[460px] sm:h-[460px] relative'>
                <img
                  alt={blogsDetails?.title}
                  className="relative w-full h-full object-cover"
                  referrerpolicy="no-referrer"
                  src={blogsDetails?.image}
                />
                <div className='absolute -top-3 -left-3 w-full h-full bg-[#ba12fe] -z-[9999]'
                  style={{ boxShadow: '0 0 20px 0 #ba12fe' }}></div>
              </div>
              <p className='text-sm font-medium tracking-wide text-center'>Bitcoin Casino Software: Importance, Features, and Functions</p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default BlogHead
