import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { DownArrow2, RemoveCartIcon, UpArrow } from '../../utils/Icons';
import { updateCartQuantity, removeFromCart } from '../../redux/slice/gamesSlice';
import { Link, useNavigate } from 'react-router-dom';

const CartItem = ({ game, index, isLast }) => {
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(game.quantity);

    const handleChange = (e) => {
        let newQuantity = parseInt(e.target.value, 10);

        if (newQuantity >= 10) {
            setQuantity(newQuantity);
            dispatch(updateCartQuantity({ id: game.id, quantity: newQuantity }));
        }
    };

    const increment = () => {
        let newQuantity = quantity + 1;
        setQuantity(newQuantity);
        dispatch(updateCartQuantity({ id: game.id, quantity: newQuantity }));
    };

    const decrement = () => {
        if (quantity > 10) {
            let newQuantity = quantity - 1;
            setQuantity(newQuantity);
            dispatch(updateCartQuantity({ id: game.id, quantity: newQuantity }));
        }
    };

    const handleRemove = () => {
        dispatch(removeFromCart({ id: game.id }));
    };

    return (
        <div
            className={`flex justify-between items-center my-2 w-full flex-col sm:flex-row space-y-6 ${!isLast ? 'border-b-2 border-gray-700 pb-6' : ''}`}
        >
            <div className="flex items-center gap-6 w-full">
                <div className='w-20 h-20 overflow-hidden rounded-md  border-2 border-[#FFDD15] '>
                    <img src={game.game_image} alt={game.game_name} className="w-full h-full" />
                </div>
                <div className="text-white text-lg font-semibold">{game.game_name}</div>
            </div>
            <div className="flex items-center w-full justify-between flex-row-reverse sm:flex-row">
                <div className="flex justify-between items-center bg-[#0E0E0E] border border-white rounded w-20 h-8">
                    <input
                        type="text"
                        value={quantity}
                        onChange={handleChange}
                        className="w-10 bg-[#0E0E0E] text-white text-center outline-none flex-1"
                    />
                    <div className="flex flex-col justify-center items-center gap-1 border-l flex-1">
                        <UpArrow className="w-4 cursor-pointer" onClick={increment} />
                        <DownArrow2 className="w-4 cursor-pointer" onClick={decrement} />
                    </div>
                </div>
                <div className="text-[#01D370] font-bold text-lg">${game.game_price}</div>
                <button onClick={handleRemove} className="text-red-500 text-lg font-semibold bg-transparent">
                    <RemoveCartIcon />
                </button>
            </div>
        </div>
    );
};

const CartMain = ({ cartData }) => {
    const navigate = useNavigate();
    const total = cartData.reduce((acc, game) => acc + game.game_price * game.quantity, 0);

    const handleCheckout = () => {
        if (cartData.length > 0) {
            // Pass the 'isCheckoutAllowed' state when navigating
            navigate('/checkout', { state: { isCheckoutAllowed: true } });
        } else {
            // Navigate to home if no items in the cart
            navigate('/');
        }
    };
    return (
        <div className="flex flex-wrap space-y-8 lg:space-y-0 justify-between items-start p-4">
            <div className='w-full lg:w-[70%] flex flex-col gap-2 items-start justify-between rounded-lg p-8 bg-[#1f2937]'
             style={{
                borderColor: 'rgba(255, 255, 255, 0.16)',
                borderWidth: '1px'
              }}
            >
                <h2 className="text-white text-2xl font-semibold mb-6">Cart ({cartData.length} items)</h2>
                {cartData.map((game, index) => (
                    <CartItem key={game.id} game={game} index={index} isLast={index === cartData.length - 1} />
                ))}
            </div>
            <div className="w-full lg:w-[25%]">
                <div className="w-full rounded-lg flex flex-col gap-5 text-white bg-[#290A47] border-l-[3px] border-b-[3px] border-[#EC29FC] py-6 px-6">
                    <h3 className="text-xl font-semibold">Order Summary</h3>
                    <div className='flex flex-row justify-between items-center font-semibold'>
                        <p>Subtotal:</p>
                        <p>${total.toFixed(2)}</p>
                    </div>
                    <div className='flex flex-row justify-between items-center font-semibold'>
                        <p>Total:</p>
                        <p>${total.toFixed(2)}</p>
                    </div>
                    <button type='button' onClick={handleCheckout} className="text-[#0E0E0E] bg-[#FFDD15] flex justify-center items-center gap-2 px-5 font-semibold py-2 rounded-md">Checkout</button>
                </div>
                <div className='flex items-center justify-center mt-5 font-semibold text-white text-base'>
                    Or <Link to="/deposit" className='pl-3 text-[#FFDD15] font-extrabold hover:underline hover:text-white hover:font-black'>Continue shopping</Link>
                </div>
            </div>
        </div>
    );
}

export default CartMain;
