import React from 'react';


const PaymentButton = ({ title, bgColor, logo, onClick = () => { } }) => {
    return (
        <button className={`flex justify-center items-center px-4 py-2.5 rounded-lg`} onClick={onClick} style={{
            backgroundColor: bgColor
        }}>
            <img src={logo} alt="Logo" className="w-5 h-5 mr-2" />
            <span className="text-white font-semibold">{title}</span>
        </button>
    );
};

export default PaymentButton;
