import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "react-datepicker/dist/react-datepicker.css";

import Error404 from './components/Common/Error404';
import Error500 from './components/Common/Error500';

import DashboardLayout from './components/DashboardLayout/Layout';
import Layout from './components/Layout/Layout';

import Home from './pages/HomeLayout/Home/Home';
import About from './pages/HomeLayout/About/About';
import Blog from './pages/HomeLayout/Blog/Blog';
import BlogDetails from './pages/HomeLayout/Blog/BlogDetails';
import Deposit from './pages/HomeLayout/DepositNow/Deposit';
import Cart from './pages/HomeLayout/Cart/Cart';
import CheckOut from './pages/HomeLayout/CheckOut/CheckOut';
import Faq from './pages/HomeLayout/Faq/Faq';
import ContactUs from './pages/HomeLayout/Contactus/ContactUs';
import Support from './pages/HomeLayout/Support/Support';
import GamesDescription from './pages/HomeLayout/Home/GamesDescription';
import PlatformDescription from './pages/HomeLayout/Home/PlatformDescription';
import Login from './pages/HomeLayout/Auth/Login';
import Signup from './pages/HomeLayout/Auth/Signup';
import ForgotPassword from './pages/HomeLayout/Auth/ForgotPassword';
import SocialReward from '././components/SocialReward';
import Bonuses from './pages/DashboardLayout/Bonus/Bonuses';
import BonusesLevel from './pages/DashboardLayout/BounesLevel/BonusesLevel';
import Profile from './pages/DashboardLayout/Profile/Profile';
import Dashboard from './pages/DashboardLayout/Dashboard/Dashboard';
import Games from './pages/DashboardLayout/MyGames/Games';
import Referral from './pages/Referrals/Referral';
import ReferralProgram from './pages/Referrals/ReferralProgram';
import RequestRedeem from './pages/DashboardLayout/RequestRedeem/RequestRedeem';
import Withdrawals from './pages/DashboardLayout/Withdrawals/Withdrawals';
import WithdrawalsView from './pages/DashboardLayout/Withdrawals/WithdrawalsView';
import DashboardTransactionView from './pages/DashboardLayout/Dashboard/DashboardTransactionView';
import UserDeposits from './pages/DashboardLayout/Deposit/UserDeposits';
import DepositView from './pages/DashboardLayout/Deposit/DepositView';
import DepositToWallet from './pages/DashboardLayout/Deposit_to_Wallet/DepositToWallet';
import DepositToGame from './pages/DashboardLayout/Deposit_to_Game/DepositToGame';

import CashAppPage from './pages/PaymentPage/CashAppPage';
import AmountDeposit from './pages/PaymentPage/AmountDeposit';

import PromotionMessageView from './pages/DashboardLayout/Messages/PromotionsMessages/PromotionMessageView';
import Promotions from './pages/DashboardLayout/Messages/PromotionsMessages/Promotions';
import SystemMessages from './pages/DashboardLayout/Messages/SystemMessages/SystemMessages';
import SystemMessagesView from './pages/DashboardLayout/Messages/SystemMessages/SystemMessagesView';

import TermsService from './pages/TermsService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { IKContext } from 'imagekitio-react'; // Import IKContext from imagekitio-react

import AuthProtect from './protect/AuthProtect';
import ResetPassword from './pages/HomeLayout/Auth/ResetPassword';
import AllPlatform from './pages/DashboardLayout/Platform/AllPlatform';
import Wheel from './pages/Spin/Wheel';
import { useDispatch } from 'react-redux';
import Loading from './components/Common/Loading';
import { resetGamesState } from './redux/slice/gamesSlice';
import { logout } from './redux/slice/authSlice';
import { clearCouponCode } from './redux/slice/couponSlice';
import Platform from './pages/HomeLayout/Platform/Platofrom';
import GamesPage from './pages/HomeLayout/Games/Games';
import OneSignal from 'react-onesignal';
import { PayPalScriptProvider } from '@paypal/react-paypal-js'; // Import PayPalScriptProvider
import usePreventActions from './usePreventActions';

function App() {
  const location = useLocation();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // Default loading state is true
  const path = location.pathname;
  usePreventActions(); // Call this hook unconditionally

  useEffect(() => {
    // Ensure this code runs only on the client side
    if (typeof window !== 'undefined') {
      OneSignal.init({
        appId: 'ea785de4-b229-41a1-a4fa-879870cba1d2',
        // You can add other initialization options here
        notifyButton: {
          enable: true,
        },
        // Uncomment the below line to run on localhost. See: https://documentation.onesignal.com/docs/local-testing
        //allowLocalhostAsSecureOrigin: true
      });
    }}, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  useEffect(() => {
    if (path === '/login') {
      setLoading(true);
      // Reset games slice && Reser auth slice (or any other slice if needed)
      dispatch(resetGamesState());
      dispatch(clearCouponCode());
      dispatch(logout());
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [path, dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
    <IKContext
      urlEndpoint="https://ik.imagekit.io/luckycharm" // Your ImageKit URL endpoint
    >
    <PayPalScriptProvider options={{ "client-id": "AYUkJvWtGijSoY9BofwqvOnxplNVUtTngJp0ZATGlsJfoTL4mdlmw02HNAkkpZcoKB48wuwlCzyrfV_S" }}>

      <Routes>

        <Route element={<Error404 />} path={`/404`} />
        <Route element={<Error500 />} path={`/500`} />

        <Route element={<Layout />} >
        <Route path="/" element={<Home />} />
          <Route path='*' element={<Navigate to={`/404`} />} />
          <Route path="/home" element={<Home />} />
          <Route path="/platform" element={<Platform />} />
          <Route path="/games" element={<GamesPage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/category/:id" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetails />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/referral/program" element={<ReferralProgram />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/social-reward" element={<SocialReward />} /> 

          <Route path="/games/description/:game_slug" element={<GamesDescription />} />

          <Route path="/platform/description/:platform_slug" element={<PlatformDescription />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="cashapp" element={<CashAppPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsService />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:id" element={<ResetPassword />} />
          <Route path="/sign-up" element={<Signup />} />
          <Route path="/support" element={<Support />} />
          <Route path="/wheel" element={<Wheel />} />

          <Route element={<AuthProtect />}>
            <Route path="/cart" element={<Cart />} />
            <Route element={<DashboardLayout />}>
              <Route path="/bonuses/level" element={<BonusesLevel />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/bonuses" element={<Bonuses />} />
              <Route path="/user/message/promotions" element={<Promotions />} />
              <Route path="/user/message/system" element={<SystemMessages />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/user/withdrawals" element={<Withdrawals />} />
              <Route path="/user/deposits" element={<UserDeposits />} />
              <Route path="/user/request/redeem" element={<RequestRedeem />} />
              <Route path="/user/referrals" element={<Referral />} />
              <Route path="/user/payments" element={<DepositToWallet />} />
              <Route path="/user/deposit/game" element={<Navigate to="/deposit" />} /> {/* Redirection */}
              <Route path="/user/message/promotion/:id" element={<PromotionMessageView />} />
              <Route path="/user/message/system/:id" element={<SystemMessagesView />} />
              <Route path="/mygames" element={<Games />} />
              <Route path="/user/withdrawals/:id" element={<WithdrawalsView />} />
              <Route path="/user/all-platform" element={<AllPlatform />} />
            </Route>
            <Route path="/user/wallet/:id/:order_id" element={<DashboardTransactionView />} />
            <Route path="/user/deposits/:id/:order_id" element={<DepositView />} />
            <Route path="/user/deposits/amount" element={<AmountDeposit />} />
          </Route>
        </Route>
      </Routes>
      </PayPalScriptProvider> {/* End of PayPalScriptProvider */}
      </IKContext>
    </>
  );
}

export default App;
