import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo from '../assets/image/luckcharmsweep.png';
import Img30 from '../assets/image/30.png';
import treasureBox from '../assets/image/treasureBox.png';
import Bg from '../assets/image/popup-img-X-bg.png';
import Spinner from '../assets/image/spinner.png';
import flamingo from '../assets/image/flamingo.png';
import { Link } from 'react-router-dom';

const Popup = ({ isVisible, onClose, images, bgImg }) => {
    if (!isVisible) return null;

    const CustomPrevArrow = ({ onClick }) => (
        <div
            className="absolute bottom-4 right-14 z-10 cursor-pointer text-white h-7 flex justify-center items-center w-7 bg-[#962ed1]  rounded-md"
            onClick={onClick}
        >
            {"<"}
        </div>
    );

    const CustomNextArrow = ({ onClick }) => (
        <div
            className="absolute bottom-4 right-4 z-10 cursor-pointer text-white h-7 flex justify-center items-center w-7 bg-[#962ed1] rounded-md"
            onClick={onClick}
        >
            {">"}
        </div>
    );

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    autoplay: true,
                    speed: 1000,
                    autoplaySpeed: 5000,
                },
            },
        ],
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="relative w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-white text-2xl md:text-3xl font-bold z-10 bg-transparent"
                >
                    &times;
                </button>
                <div className="rounded-lg shadow-lg relative">
                    <Slider {...settings}>

                    <div className="relative h-[380px] xs:h-[350px] overflow-hidden flex flex-col items-center justify-center text-white p-4 md:p-5 rounded-lg" style={{ backgroundImage: `url(${images[7]})` }}> 
  <div className="absolute inset-0 bg-black opacity-80 rounded-lg"></div> 

  <div className="relative z-10 text-center"> 
    <p className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-shadow-md"> 
      Claim Upto 
    </p>
    <p className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-[#FFD700] text-shadow-lg">
      $20 Bonus
    </p>
    <p className="mt-2 text-lg sm:text-xl">Sign up at LuckCharm Sweep and start playing!</p>

    <div className="mt-4 flex flex-col sm:flex-row gap-2 justify-center">
    <Link to="/sign-up"> 
  <button className="bg-[#FFD700] hover:bg-[#DAA520] text-black font-bold py-2 px-4 rounded-md">
    Register Now
  </button>
</Link>
    </div>

    {/* New note about multiple accounts */}
    <p className="mt-4 text-xs text-yellow-300">
      One account per person. Multiple accounts are strictly prohibited.
    </p> 
  </div>
</div>


                       

                        {/* Slide 4 */}
                        <div className="relative flex flex-col justify-end items-end text-white p-5 h-[380px] sm:h-[350px] overflow-hidden">
                            <div className="absolute inset-0 bg-cover bg-center rounded-lg bg-[#23035C]"
                                style={{
                                    backgroundImage: `url(${Bg})`,
                                }}
                            ></div>
                            <div className="text-white rounded-lg relative flex flex-col md:flex-row justify-center items-center h-full">
                                <div className='md:mr-6 flex-1'>
                                    <div className="text-xl md:text-3xl font-bold">Wheel of Fortune</div>
                                    <p className="mt-2 text-lg md:text-xl">
                                        Spin the Fortune Wheel for Exclusive
                                        rewards, including free spins, deposit
                                        match bonuses, and free plays!
                                    </p>
                                    <Link to="/wheel" className="mt-2 sm:mt-6 bg-[#EC29FC] text-white font-bold py-2 px-4 rounded inline-block">
                                        LEARN MORE
                                    </Link>
                                </div>
                                <div className='flex-[0.3]'>
                                    <img src={Spinner} alt="" className='w-[100px] md:w-full mt-5 md:mt-0 object-contain' />
                                </div>
                            </div>
                        </div>

                       


                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Popup;
