import { toast } from "react-toastify";
import axios from "axios";

export const ApiHandler = async (url, method, body, header, dispatch, navigate) => {
  const api = 'https://api.luckycharmsweep.com/api/v2/dfjfngjn' + url;

  try {
    const headers = {
      'Content-Type': 'application/json',
    };

    // Add Authorization header only if the 'header' value is provided
    if (header) {
      headers.Authorization = `Bearer ${header}`;
    }

    // Axios API request
    const response = await axios({
      url: api,
      method: method,
      headers: headers,
      data: body !== undefined && body !== null ? body : undefined,
    });

    const result = response.data.status;

    // Handle responses with custom status codes or HTTP errors
    if (response.status === 401 || response.status === 400 || result.code === 401) {
      toast.error(result.message || "Unauthorized. Please log in again.");
      navigate("/login"); // Redirect to login on 401 errors
    } else if (result.code === 404) {
      toast.error(result.message || "Unauthorized. Please log in again.");
      throw new Error(result.message);
    } else if (result.code === 500) {
      toast.error("Something went wrong. Please try again.");
      navigate("/500");
    }

    return response;
  } catch (error) {
    const result = error.response.data.status;
    const errorMessage = error.response?.data?.status?.message || "An unexpected error occurred.";

    // Handle non-200 responses based on custom response codes (401, 404, etc.)
    if (error.response.status === 401 || error.response.status === 400) {
      toast.error(result.message || "Unauthorized. Please log in again.");
      navigate("/login");
    } else if (result.code === 401) {
      toast.error(result.message || "Unauthorized. Please log in again.");
      navigate("/login");
    } else if (result.code === 404) {
      toast.error(result.message || "Unauthorized. Please log in again.");
      throw new Error(result.message);
    } else if (result.code === 500) {
      toast.error("Something went wrong. Please try again.");
      navigate("/500");
    }else {
      toast.error(errorMessage);
      console.error("API Error:", error); // Log unexpected errors
    }
  }
};
