import React, { useEffect, useState, useRef } from 'react';
import { ApiHandler } from '../../helper/ApiHandler';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getImageUrl } from '../../utils/getImageUrl'; // Helper function for background image

const ContentSpin = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [recentWinners, setRecentWinners] = useState([]);
  const [isBgLoaded, setIsBgLoaded] = useState(false); // State for background image loading
  const divRef = useRef(null); // Ref for lazy loading the background image

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  const toggleDropdown = (index) => {
    setOpenDropdown((prev) => (prev === index ? null : index));
  };

  useEffect(() => {
    const fetchRecentWinners = async () => {
      try {
        const response = await ApiHandler(
          '/recent_winners.php',
          'POST',
          undefined,
          undefined,
          dispatch,
          navigate
        );
        setRecentWinners(response.data.data);
      } catch (error) {
        console.error('Error fetching recent winners:', error);
      }
    };

    fetchRecentWinners();
  }, [token, dispatch, navigate]);

  // Use IntersectionObserver to implement lazy loading for the background image
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsBgLoaded(true); // Load background image when in view
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  const backgroundImageUrl = getImageUrl('/assets/image/leaderboardBg.png'); // Use helper function

  return (
    <div>
      <div className="text-white container mx-auto gap-5 flex flex-col lg:flex-row">
        <div className="flex-1 px-4 lg:px-0">
          <div className="text-[14px] lg:text-[16px] leading-relaxed">
            <p>Get ready for the adventure of your life as you spin the wheel of fortune and win immense wealth! Lucky Charm Sweep Fortune Wheel allows you to enhance your gaming experience.</p>
            <p className="mt-5">All you have to do is spin the Fortune Wheel daily and get incredible rewards, from coupon codes to free plays. Here's everything you need to know about Fortune Wheel.</p>
          </div>

          <div className="mt-5 text-[14px] lg:text-[16px] leading-relaxed">
            <h2 className="text-xl lg:text-2xl font-bold">Sign in Now, Spin the Wheel, and Win!</h2>
            <p className="mt-5">Sign in right now, spin the wheel, and win incredible rewards! Whether you win coupon codes or free plays, you can play free games and win real money every day. So don’t let this opportunity pass you by.</p>
            <p>Claim your rewards and start winning!</p>
            <p>Fortunes await you as you spin the wheel!</p>
          </div>
        </div>

        <div className="flex-1 px-4 lg:px-0">
          <div className="w-full mx-auto">
            <button
              className="bg-[#290A47] hover:bg-[#EC29FC] border border-[#EC29FC] text-white font-bold py-2 px-4 rounded-lg w-full flex justify-between items-center"
              onClick={() => toggleDropdown(1)}
            >
              <span>How to Enter the Fortune Wheel Campaign</span>
              <span className={`${openDropdown === 1 ? 'rotate-180' : ''} transition-transform duration-300`}>▼</span>
            </button>

            <div
              className={`mt-2 bg-[#290A47] text-white rounded-lg shadow-lg transition-all duration-300 overflow-hidden ${openDropdown === 1 ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}`}
            >
              <p className="text-sm p-4">
                It’s very easy to enter the Fortune Wheel campaign. When you log in to Lucky Charm Sweep, you’ll see a spinning wheel below your screen, whether on your PC, tablet, or smartphone...
              </p>
            </div>

            {/* Repeat for other dropdowns */}
          </div>

          <div className="mt-5 text-[#FF2B62] font-extrabold text-lg leading-relaxed">
            <p>NOTICE: Please consider that only players who have made at least $10 worth of deposit at Lucky Charm Sweep in the last 7 days are eligible for using Wheel of Fortune.</p>
          </div>
        </div>
      </div>

      <div
        ref={divRef} // Attach ref for lazy loading
        style={{
          backgroundImage: isBgLoaded ? `url(${backgroundImageUrl})` : 'none', // Load background image when in view
          backgroundSize: 'cover',
        }}
        className="mt-10 py-10"
      >
        <h2 className="text-center text-2xl text-[#FFDD15] font-bold uppercase">
          Recent Winners
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 gap-y-10 mt-10 container mx-auto">
          {recentWinners.map((winner, index) => (
            <div
              key={index}
              className="bg-[#222222] border rounded-lg p-5 flex flex-col items-center relative h-[150px] justify-center"
            >
              <img
                src={`https://api.dicebear.com/9.x/pixel-art/svg?seed=${winner.first_name}`}
                alt={`${winner.first_name} ${winner.last_name}`}
                className="rounded-full w-16 h-16 mb-3 absolute -top-8"
                loading="lazy" // Add lazy loading for avatar image
              />
              <span className="text-lg text-white mb-1 mt-4">
                {winner.first_name} {winner.last_name}
              </span>
              <span className="text-[#26E9B1] text-lg mb-1">
                {winner.title}
              </span>
              <span className="text-gray-400">
                {moment(winner.created_date).fromNow()}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContentSpin;
