import React from 'react';
import { useNavigate } from 'react-router-dom';
import MW from '../../assets/image/luckcharmsweep.png';

const About = () => {
  const navigate = useNavigate();

  return (
    <div className='container mx-auto my-10 px-4'>

      {/* Section 1: Welcome to Lucky Charm Sweep */}
      <section className='bg-[#290A47] p-6 border-b-[3px] border-[#EC29FC] rounded-lg text-white mb-10'>
        <div className='flex flex-col md:flex-row gap-5'>
          <div className='w-full md:w-1/2 flex justify-center items-center'>
            <div className='px-5 py-10 bg-[] rounded-lg'>
              <img src={MW} alt="Lucky Charm Sweep" className='w-full md:w-[300px] object-contain' />
            </div>
          </div>
          <div className='w-full md:w-1/2 flex flex-col gap-3'>
            <h1 className='uppercase font-bold text-xl sm:text-2xl'>
              Welcome to Lucky Charm Sweep!
            </h1>
            <p className='text-[#CACACA] text-sm sm:text-base'>
              Looking for fun and big wins? Lucky Charm Sweep is your go-to online sweepstakes platform. We bring you top-tier slot games, thrilling gameplay, and instant, hassle-free payouts right at your fingertips!
            </p>
            <p className='text-[#CACACA] text-sm sm:text-base'>
              Our easy-to-use interface and lightning-fast transactions make every moment count. Why wait when the excitement is just a click away? Join us today!
            </p>
            <button
              className="bg-[#FFDD15] text-black font-bold py-2 px-4 rounded text-sm sm:text-base mt-3"
              onClick={() => navigate('/sign-up')}
            >
              REGISTER NOW
            </button>
          </div>
        </div>
      </section>

      {/* Section 2: Why Choose Lucky Charm Sweep */}
      <section className='bg-[#290A47] p-6 border-b-[3px] border-[#EC29FC] rounded-lg text-white mb-10'>
        <h2 className='text-2xl font-bold mb-3'>Why Choose Lucky Charm Sweep?</h2>
        <p className='text-[#CACACA] text-sm sm:text-base'>
          We’re not just another sweepstakes platform. We’re your gateway to a world of excitement, security, and rewards. Here’s what makes us stand out:
        </p>
        <ul className='list-disc pl-5 mt-3 text-[#CACACA] text-sm sm:text-base'>
          <li>⚡ <strong>Fast & Easy Withdrawals:</strong> Get your winnings instantly, with no delays.</li>
          <li>🔒 <strong>Top-Notch Security:</strong> Your data is safe with our state-of-the-art encryption.</li>
          <li>🎰 <strong>Massive Game Variety:</strong> Choose from hundreds of games across 20+ platforms.</li>
        </ul>
      </section>

      {/* Section 3: Our Game Platforms */}
      <section className='bg-[#290A47] p-6 border-b-[3px] border-[#EC29FC] rounded-lg text-white mb-10'>
        <h2 className='text-2xl font-bold mb-4'>Our Game Platforms</h2>
        <p className='text-[#CACACA] text-sm sm:text-base'>
          At Lucky Charm Sweep, we’ve partnered with the best game providers to bring you a one-of-a-kind experience. Here’s a taste of the platforms we support:
        </p>
        <div className='grid grid-cols-2 md:grid-cols-3 gap-4 mt-4 text-[#CACACA] text-sm sm:text-base'>
          <p>Milky Way</p>
          <p>Fire Kirin</p>
          <p>Juwa</p>
          <p>Game Vault</p>
          <p>Orion Star</p>
          <p>River Sweep</p>
          <p>Blue Dragon</p>
          <p>Golden Treasure</p>
          <p>Para Casino</p>
          <p>Game Room</p>
          <p>Mafia</p>
          <p>Vegas Sweep</p>
          <p>Cash Machine</p>
          <p>V-blink</p>
          <p>Ultra Panda</p>
          <p>Panda Master</p>
          <p>Lucky Star</p>
          <p>Moolah</p>
          <p>E-Games</p>
          <p>Cash Frenzy</p>
          <p>Joker</p>
        </div>
      </section>

      {/* Section 4: Our Commitment to Fair Play */}
      <section className='bg-[#290A47] p-6 border-b-[3px] border-[#EC29FC] rounded-lg text-white mb-10'>
        <h2 className='text-2xl font-bold mb-4'>Our Commitment to Fair Play</h2>
        <p className='text-[#CACACA] text-sm sm:text-base'>
          Trust and fairness are the pillars of Lucky Charm Sweep. Every game is rigorously tested to ensure equal chances for all players. We believe in transparency and make sure our community feels confident and secure.
        </p>
      </section>

      {/* Section 5: 24/7 Customer Support */}
      <section className='bg-[#290A47] p-6 border-b-[3px] border-[#EC29FC] rounded-lg text-white mb-10'>
        <h2 className='text-2xl font-bold mb-3'>24/7 Customer Support</h2>
        <p className='text-[#CACACA] text-sm sm:text-base'>
          Need help? Our support team is always here for you, day or night. Whether you have a question or face an issue, we provide fast and friendly assistance so you can get back to winning!
        </p>
        <button
          className="bg-[#FFDD15] text-black font-bold py-2 px-4 rounded text-sm sm:text-base mt-3"
          onClick={() => navigate('/support')}
        >
          CONTACT SUPPORT
        </button>
      </section>

      {/* Section 6: Getting Started is Easy */}
      <section className='bg-[#290A47] p-6 border-b-[3px] border-[#EC29FC] rounded-lg text-white mb-10'>
        <h2 className='text-2xl font-bold mb-4'>Getting Started is Easy!</h2>
        <p className='text-[#CACACA] text-sm sm:text-base'>
          Want to join the fun? Here’s how:
        </p>
        <ol className='list-decimal pl-5 mt-3 text-[#CACACA] text-sm sm:text-base'>
          <li>Create your free account.</li>
          <li>Deposit funds using cash or crypto.</li>
          <li>Start playing and winning instantly!</li>
        </ol>
        <button
          className="bg-[#FFDD15] text-black font-bold py-2 px-4 rounded text-sm sm:text-base mt-4"
          onClick={() => navigate('/sign-up')}
        >
          SIGN UP NOW
        </button>
      </section>

      {/* Section 7: Unmatched Security & Reliability */}
      <section className='bg-[#290A47] p-6 border-b-[3px] border-[#EC29FC] rounded-lg text-white mb-10'>
        <h2 className='text-2xl font-bold mb-4'>Unmatched Security & Reliability</h2>
        <p className='text-[#CACACA] text-sm sm:text-base'>
          Your safety is our priority. With top-tier encryption and a reliable infrastructure, you can play with peace of mind, knowing that your data and funds are protected.
        </p>
      </section>

      {/* Section 8: Play, Win, Withdraw - It’s That Simple */}
      <section className='bg-[#290A47] p-6 border-b-[3px] border-[#EC29FC] rounded-lg text-white mb-10'>
        <h2 className='text-2xl font-bold mb-4'>Play, Win, Withdraw - It’s That Simple!</h2>
        <p className='text-[#CACACA] text-sm sm:text-base'>
          We’ve made winning easy and fun. With our seamless system, your winnings are just a click away. Why wait? Start your winning streak today!
        </p>
        <button
          className="bg-[#FFDD15] text-black font-bold py-2 px-4 rounded text-sm sm:text-base mt-3"
          onClick={() => navigate('/deposit')}
        >
          START NOW
        </button>
      </section>
    </div>
  );
};

export default About;
