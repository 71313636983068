import React from 'react'

const MainContent = () => {
    return (
        <div className="mx-auto container my-10 terms">
            <div className="space-y-6 text-white policy">
                <div className='space-y-3'>
                    <p>
                        Please read these terms and conditions carefully before using any Lucky Charm Sweep services. Make sure you understand what is contained in this agreement.
                    </p>
                    <p>
                        You and Lucky Charm Sweep have a legally binding agreement (the "Agreement") formed by these Terms and Conditions and the Privacy Policy.
                    </p>
                    <p>
                        When you create an account with Lucky Charm Sweep, you agree that you have read, understood, and agreed to these terms and conditions and the Privacy Policy and that they apply to you.
                    </p>
                    <p>
                        You must stop using the Services immediately if you disagree with any part of the Privacy Policy or these Terms and Conditions.
                    </p>
                    <p>
                        We advise you to consult an attorney in your jurisdiction if you have questions about your rights and obligations under this Agreement.
                    </p>
                    <p>
                        Before you register, understand the laws of your jurisdiction, as Lucky Charm Sweep disclaims all liability for anything related to jurisdiction.
                    </p>
                    <p>
                        January 18, 2024
                    </p>
                </div>

                <ol className="ml-6 space-y-8">
                    <li>
                        <span className='text-white font-black text-xl'>Purpose of This Contract</span>
                        <ol className='ml-6 space-y-4 py-2'>
                            <li>
                                The purpose of the Terms and Conditions is to govern a Player’s and Lucky Charm Sweep’s legal relations.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <span className='text-white font-black text-xl'>Definitions</span>
                        <ol className='ml-6 space-y-4 py-2'>
                            <li>
                                The following terms and words used in this contract have the meaning explained below unless the context clearly means something else. {`They govern the Player’s use of Lucky Charm Sweep.`}
                                <ol className='ml-6 space-y-4 py-2'>
                                    <li>
                                        <b>Account</b> means the personal online gaming account that a player creates on the Website to access and use the games that{` Lucky Charm Sweep’s`} platforms offer.
                                    </li>
                                    <li>
                                        <b>Website, Lucky Charm Sweep, Us, or We</b> means
                                        <a href="/" className="text-blue-500 hover:underline"> www.Luckycharmsweep.com </a>,
                                        including, if applicable, any mobile or mobile applications. Unless otherwise stated, any reference to the Website in these Terms and Conditions refers to both
                                        <a href="/" className="text-blue-500 hover:underline"> www.Luckycharmsweep.com </a>
                                        and the casino apps Lucky Charm Sweep offers.
                                    </li>
                                    <li>
                                        <b>Agreement</b> means the Terms and Conditions on this page, including the
                                        <a href="/privacy-policy" className="text-blue-500 hover:underline">Privacy Policy</a>.
                                    </li>
                                    <li>
                                        <b>Username and Password</b> refers to the password and username you use when creating an account with Lucky Charm Sweep.
                                    </li>
                                    <li>
                                        <b>Player or You</b> means the person with an Account who uses the Services.
                                    </li>
                                    <li>
                                        <b>Service or Services</b> refers to your use of games, services, and any related content or software on Lucky Charm Sweep, whether downloadable or not, which allows you to use Lucky Charm Sweep’s services. The Services include both Lucky Charm Sweep’s exclusive content and content from third-party providers.
                                    </li>
                                    <li>
                                        <b>Terms and Conditions</b> refer to the Terms and Conditions on this page.
                                    </li>
                                    <li>
                                        <b>Privacy Policy</b> refers to the privacy policy at
                                        <a href="/privacy-policy" className="text-blue-500 hover:underline">https://LuckyCharmSweep.com/privacy-policy</a>.
                                    </li>
                                </ol>
                            </li>

                            <li>
                                <span className='text-white font-black text-xl'>Unless otherwise specified by the context, the following terms and conditions apply:</span>
                                <ol className='ml-6 space-y-4 py-2'>
                                    <li>
                                        Any references to these Terms and Conditions that are amended also refer to any Terms and Conditions that supersede them.
                                    </li>
                                    <li>
                                        Unless otherwise specified, these Terms and Conditions will take priority over any other document mentioned in these Terms and Conditions in case of conflict.
                                    </li>
                                    <li>
                                        All references to Lucky Charm Sweep include their respective beneficiaries, successors, or transferees.
                                    </li>
                                    <li>
                                        The section headings are only for references and {`don’t`} affect these Terms and Conditions.
                                    </li>
                                    <li>
                                        Any individual, business, partnership, association, organization, institution, trust, or agency with a separate legal identity is referred to as a "person" in these Terms and Conditions.
                                    </li>
                                    <li>
                                        Any mention of "bets" also refers to "stakes" and/or "wagers."
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <span className='text-white font-black text-xl'>Agreement to Terms and Conditions</span>
                        <ol className='ml-6 space-y-4 py-2'>
                            <li>
                                You acknowledge that you have read and understood these Terms and Conditions when you create an Account on Lucky Charm Sweep. These Terms and Conditions may be modified from time to time, and you agree that they will govern how you use the Services.
                            </li>
                            <li>
                                You acknowledge that the Rules and the Special Terms are a part of these Terms and Conditions.
                            </li>
                            <li>
                                If the Rules, the Special Terms, or the Terms and Conditions conflict, we shall take the below actions in the following order:
                                <ol className='ml-6 space-y-4 py-2'>
                                    <li>
                                        The Rules.
                                    </li>
                                    <li>
                                        The Special Terms.
                                    </li>
                                    <li>
                                        These Terms and Conditions which do not include the Special Terms and the Rules.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                The Agreement comes into effect when you create your Account and tick the acceptance box during registration. By doing this, you agree that:
                                <ol className='ml-6 space-y-4 py-2'>
                                    <li>
                                        You've read, understood, and agree to these Terms and Conditions.
                                    </li>
                                    <li>
                                        Gambling is permitted by law in the country where you access the Services, and you're legally allowed to gamble in your country.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                You agree to the Terms and Conditions when you use the Services. If there are any changes, you will be notified in writing, and your consent will be required before the changes take effect.
                            </li>
                            <li>
                                These Terms and Conditions may be available in multiple languages for your convenience and information. However, only the English version governs the legal relationship between Lucky Charm Sweep and the Player. If there is a discrepancy between other languages, the English version takes precedence.
                            </li>
                            <li>
                                These Terms & Conditions reserve any rights not expressly granted.
                            </li>
                            <li>
                                Stop using the Services if you do not entirely agree with these Terms and Conditions.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <span className='text-white font-black text-xl'>Opening an Account</span>
                        <ol className='ml-6 space-y-4 py-2'>
                            <li>
                                You must complete an online registration form to create an Account on Lucky Charm Sweep. You must enter your email address, date of birth, first and last name, phone number, and username.
                            </li>
                            <li>
                                You warrant that you are not currently banned from Lucky Charm Sweep or any other gambling platform when you register for an Account.</li>
                            <li>
                                By accepting these Terms, you agree that the funds you use on the Websites are legitimate and that the personal information you have provided is accurate. You also agree that you will not use the Websites for any fraudulent or illegal purpose, such as money laundering, in violation of any laws that apply to you and/or Lucky Charm Sweep.
                            </li>
                            <li>
                                You cannot request to create or have an Account if you will not be using the Account or if you are acting on behalf of someone else.
                            </li>
                            <li>
                                Players are limited to one account on Lucky Charm Sweep. We have the right to close any multiple Accounts that are associated with the same individual or that share the same name. Lucky Charm Sweep has the right to take action if an individual opens several accounts, including combining balances into the initial account. Lucky Charm Sweep can keep any money in those Accounts, up to the amount of any fees or losses.
                            </li>
                            <li>
                                Lucky Charm Sweep retains the right, at its sole discretion, to deny or terminate a {`Player’s`} request for an account. Agreements reached before the closure of an Account shall remain in effect unless these Terms permit Lucky Charm Sweep to retain winnings.
                            </li>
                            <li>
                                A Player must not allow a third party to use their account. Should they do so, the Player has all liability for any losses. A Player must notify Lucky Charm Sweep immediately and assist with the inquiry if you believe someone else has access to your Account.
                            </li>
                            <li>
                                The Player understands that Lucky Charm Sweep cannot guarantee that accessing the Websites is permitted in all jurisdictions. The Player is responsible for ensuring that their actions are allowed. If the Websites are used unlawfully, Lucky Charm Sweep will not provide legal advice on whether online gambling is allowed in the Player's country and will not be held liable for any penalties imposed by authorities.
                            </li>
                            <li>
                                A Player must refrain from creating an account if {`it’s`} against the law and/or if using our Services is illegal in your jurisdiction. The Player is responsible for confirming if this is the case or not. If Lucky Charm Sweep discovers that an Account was formed in a country or jurisdiction where using the Services is prohibited, it has the right to suspend the Account.
                            </li>
                            <li>
                                People connected to Lucky Charm Sweep, such as executives, managers, directors, staff members, consultants, agents, and their relatives (“Relatives” in this clause means the spouse, partner, parents, children, or siblings of the people mentioned above), are prohibited from opening, maintaining, or using an Account or the Service in any way. If this happens, Lucky Charm Sweep has the right to instantly close the Account, forfeit any winnings, and cancel bets.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <span className='text-white font-black text-xl'>{`Player’s Personal Information`}</span>
                        <ol className='ml-6 space-y-4 py-2'>
                            <li>
                                During the registration process, Lucky Charm Sweep may request the following but not limited to date of birth, home address, email address, phone number, and ID card number.
                            </li>
                            <li>
                                A Player must provide a Username and Password as part of their Account registration or logging into their account.
                            </li>
                            <li>
                                A Player is responsible for ensuring that all information provided to Lucky Charm Sweep is accurate, correct, and up to date.
                            </li>
                            <li>
                                A Player can contact Customer Support to change their personal information. Further documents may be required.
                            </li>
                            <li>
                                A Player is not permitted to change their date of birth, first name, or surname unless they can show proof of legal changes made according to the laws of their country.
                            </li>
                            <li>
                                Lucky Charm Sweep reserves the right to keep all personal information a Player provides even after their Account is closed.
                            </li>
                            <li>
                                A Player must choose a username that is NOT offensive, racist, threatening, defamatory, obscene, offensive, or violate any third-party property right or intellectual property right. Lucky Charm Sweep reserves the right to reject a username and prohibit the Player from using it without prior notice if we deem it inadequate.
                            </li>
                            <li>
                                After creating an Account, the Player must use their Username and Password to access their Account. The Player should keep the Username and Password private, and the Player is entirely responsible for all activities on the Website due to using the Username and Password. If a third party breaches the Terms and Conditions due to having access to the {`Player’s`} Username and Password, Lucky Charm Sweep will consider it a breach committed by the Player.
                            </li>
                            <li>
                                A Player shall immediately notify Lucky Charm Sweep if they forget their Username and Password or believe an unauthorized user is using the account.
                            </li>
                            <li>
                                The Account will be temporarily suspended following five consecutive incorrect Username and Password submissions. This is for security reasons. The Player must present an official identity document with a photo to reactivate it.
                            </li>
                            <li>
                                Lucky Charm Sweep considers transactions valid if the rightful Account owner enters the correct Username and Password. Lucky Charm Sweep is not liable for any losses a Player incurs due to misuse of personal information or unauthorized use. Lucky Charm Sweep has the right to request a valid identification, proof of address, or copy of all cards at any time to prevent identity theft. Failure to provide these documents will result in an immediate Account suspension.
                            </li>
                            <li>
                                Lucky Charm Sweep uses strict security protocols to protect the {`Player’s personal information. However, it is the Player’s`} responsibility to protect their Username and Password and all activities that occur when they access the Services through their Username and Password.
                            </li>
                            <li>
                                Lucky Charm Sweep views a {`Player’s`} personal information as highly confidential and processes them according to our Privacy Policy.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <span className='text-white font-black text-xl'>Permission to Access the Services</span>
                        <ol className='ml-6 space-y-4 py-2'>
                            <li>
                                We give you a personal, non-exclusive, and non-transferable license to use the Services under these Terms and Conditions and subject to the following conditions:
                                <ol className='ml-6 space-y-4 py-2'>
                                    <li>
                                        You must be able to accept responsibility for your conduct and be at least 21 years old, or the legal age required by your {`country’s`} laws.
                                    </li>
                                    <li>
                                        You have the personal right to use the Services to play online casino games. You are granted this right for personal, non-commercial use.
                                    </li>
                                    <li>
                                        The information on the registration form must be true, complete, accurate, and correspond to the name(s) on the bank cards used to make deposits and withdrawals. If the information you submit is false, incorrect, incomplete, or deceitful, we have the right to close your account. You are responsible for informing us immediately of any changes in the information you provided.
                                    </li>
                                    <li>
                                        You understand the Rules and guidelines about each game on the Websites.
                                    </li>
                                    <li>
                                        You are responsible for checking the payout percentage or odds for each before placing a bet, and you agree that they may change from time to time.
                                    </li>
                                    <li>
                                        You cannot, under any circumstances, allow anyone to use your Account, password, or identity to access the Services.
                                    </li>
                                    <li>
                                        We reserve the right to withhold payments that we believe are associated with or related to a crime until the issue is resolved.
                                    </li>
                                    <li>
                                        Please be advised that you instantly consume our Services when playing our games. So, we cannot offer refunds or returns of goods or, subject to any terms to the contrary, cancel your bets after they have been placed. When you use real money to play a game on the Websites, the money is instantly taken out of your Account.
                                    </li>
                                    <li>
                                        You agree that you risk losing money when playing games of chance through the Services. You are responsible for any losses incurred on your Account when you play these games of chance.
                                    </li>
                                    <li>
                                        You also agree not to decipher, reverse engineer, disassemble, decrypt, or find out the source code of all or any part of the games or the Services, download, rent, lease, loan, create derivative works, distribute, copy, re-use, adapt, modify, or solicit intellectual property protection on the content of and intellectual property rights in the Services and the games.
                                    </li>
                                    <li>
                                        You agree that any bet you place during the fault is invalid, including any wins if a game malfunctions. This also includes any winnings from subsequent game rounds, regardless of the game You play and the Services used using the said winnings.
                                    </li>
                                    <li>
                                        You acknowledge that you use the Services at your own risk and discretion.
                                    </li>
                                    <li>
                                        By using the Websites, you certify that the funds you use are not obtained illegally and won't be used for fraudulent or illegal purposes, including money laundering, according to the laws of all jurisdictions that have authority over you. If Lucky Charm Sweep has reasonable grounds to believe that the money you have used may not be legitimate, we reserve the right to terminate or block your account.
                                    </li>
                                    <li>
                                        You certify that you will not try to circumvent or interfere with {`Lucky Charm Sweep’s`} security protocols in any manner. If Lucky Charm Sweep has a reasonable reason to suspect that this condition is met, We reserve the right to stop or prohibit your account immediately and withhold any amount due you until all applicable external and internal investigations have been finished.
                                    </li>
                                    <li>
                                        Lucky Charm Sweep prohibits the misuse of bonuses, any promotional offers, or the Services in any other way.
                                    </li>
                                    <li>
                                        You acknowledge that Lucky Charm Sweep reserves the right to cancel or suspend your account immediately, retain the balance up to the amount of any losses or fees incurred by Lucky Charm Sweep, and deny you access to the Website or Services we provide if it reasonably determines that you used or attempted to use a product with artificial intelligence in conjunction with our Services.
                                    </li>
                                    <li>
                                        Our websites prohibit using recognized betting strategies to bypass the house edge in our games. This is to promote fair play. You acknowledge that Lucky Charm Sweep has the right to instantly suspend your account and retain any cash to the extent of any losses or costs incurred by Lucky Charm Sweep if your account shows any hint of deceptive gameplay through these betting strategies.
                                    </li>
                                    <li>
                                        Lucky Charm Sweep has the right to stop or permanently cancel any Accounts if we have cause to believe that specific betting methods or group wagering, for example, are being used systematically by an Account or group of Accounts. You acknowledge that under these circumstances, Lucky Charm Sweep is not obligated to return any funds to your account, except for the initial deposit, if it remains in your account.
                                    </li>
                                    <li>
                                        You agree that any money deposited into your Account will not generate interest.
                                    </li>
                                    <li>
                                        You acknowledge and understand that you will not open multiple accounts. A Player is allowed to register only one Account. If Lucky Charm Sweep finds out that a Player has several accounts, we reserve the right to suspend all the Accounts and all associated transactions until the issue is fixed.
                                    </li>
                                    <li>
                                        You certify that you will not misuse your ability to open accounts to take advantage of Lucky Charm Sweep’s bonuses and promotional offers. At its sole discretion, Lucky Charm Sweep reserves the right to cancel or prohibit Player Accounts created specifically for that purpose, along with the transactions made on such Accounts, if you engage in abusive behavior regarding Lucky Charm Sweep bonuses and/or promotional offers.
                                    </li>
                                    <li>
                                        By registering, you acknowledge that you are required to give us personal information about yourself, including, but not limited to, payment methods. You agree that we may be forced to give your information to third parties if necessary.
                                    </li>
                                    <li>
                                        All communication networks, internet services, and any other permission or license needed to access the Services are your sole responsibility.
                                    </li>
                                    <li>
                                        You acknowledge that your password and login security are solely your responsibility. We recommend using a password that combines capital and lowercase characters, numbers, and other symbols and that you keep the password for the Account private. Lucky Charm Sweep advises you to store your password in a location that is both secret and accessible to you alone.
                                    </li>
                                    <li>
                                        Lucky Charm Sweep prohibits a Player from buying, selling, transferring, and/or obtaining Accounts from other players or moving money from one player's Account to another.
                                    </li>
                                    <li>
                                        You shall not engage in any actions or behaviors that could harm the brand image or reputation of any Lucky Charm Sweep.
                                    </li>
                                    <li>
                                        You acknowledge that you will use the Services at your discretion and will be responsible for all associated risks and obligations.
                                    </li>
                                    <li>
                                        You agree that any bet you place on your Account will not violate any betting regulations enforced by the relevant gambling authorities.
                                    </li>
                                    <li>
                                        You acknowledge and agree that Lucky Charm Sweep is not liable for any taxes that you may be required to pay, whether on any bets, on any winnings, or otherwise, and that you (and not Lucky Charm Sweep) are responsible for reporting any amount you withdraw if the authorities require it.
                                    </li>
                                    <li>
                                        Players can only register an Account with Lucky Charm Sweep in their capacity. Third-party registrations and registrations on behalf of others are prohibited.
                                    </li>
                                    <li>
                                        You agree, by accepting the Terms and/or registering to use the Website, that we can periodically perform any and all identity, credit, and other verification checks that we may require for the use of the Website and our products generally or that may be mandated by applicable laws and regulations and/or the relevant regulatory authorities. You also acknowledge that third parties may provide these checks. You consent to give us all the information we need to conduct these verification checks.
                                    </li>
                                </ol>
                            </li>

                        </ol>
                    </li>

                    <li>
                        <span className='text-white font-black text-xl'>Identification and Age Verification</span>
                        <ol className='ml-6 space-y-4 py-2'>
                            <li>
                                Please be advised that it is illegal for anyone under 21 to gamble in the USA. By accepting these Terms, you agree you are at least 21.
                            </li>
                            <li>
                                Player can only use the Website and Services if you are of legal gambling age in your jurisdiction.
                            </li>
                            <li>
                                You are not authorized to use the Websites or the Service if you are less than 21 years old.
                            </li>
                            <li>
                                To stop minors from using the Website, Lucky Charm Sweep reserves the right to request identification and proof of age from a Player at any time. When registering on the Websites, a Player must enter their phone number, email address, and legitimate form of identification.
                            </li>
                            <li>
                                By registering for an Account and accepting these Terms and Conditions, you permit us to confirm your age and identity.
                            </li>
                            <li>
                                Lucky Charm Sweep reserves the right to ask for more documents if these checks cannot verify your identity and you are at least 21 years old. This may include, but is not limited to, a birth certificate, driver's license, passport, signed credit agreement, or citizen/national identity card. Lucky Charm Sweep may also request proof of address, such as a utility bill, medical note, solicitor's letter, or bank statement that is less than six months old and is mailed to your registered address.
                                We reserve the right to restrict or suspend your account until the necessary checks are completed if the requested paperwork is not provided.
                            </li>
                            <li>
                                Applicants must verify their identity and age to ensure they have the legal ability to deposit money and place bets or wagers with Lucky Charm Sweep. If we are unable to verify your identity and that you are older than 21:
                                <ol className='ml-6 space-y-4 py-2'>
                                    <li>
                                        You won't be allowed to deposit funds into your account.
                                    </li>
                                    <li>
                                        No free-to-play games will be available to you.
                                    </li>
                                    <li>
                                        You {`won’t`} be allowed to gamble with real money, free bets, or bonuses.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Lucky Charm Sweep reserves the right to reject documents that are unreadable and/or in unacceptable conditions. Your Account must include your account number or login ID to ensure the check is completed quickly.
                            </li>
                            <li>
                                If a player provides identifying documents or information that cannot be confirmed, or if there is any breach involving passports or visas, the welcome bonus package will be canceled without providing a refund or other type of compensation.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <span className='text-white font-black text-xl'>{`Lucky Charm Sweep’s Responsibilities and Declarations`}</span>
                        <ol className='ml-6 space-y-4 py-2'>
                            <li>
                                Lucky Charm Sweep is committed to honoring these Terms and Conditions by paying out all your wins.
                            </li>
                            <li>
                                Lucky Charm Sweep disclaims all liability for improper access to a Player's Account, for any potential loss from a third party using a Player's password without authorization, and/or for any transaction in which the Player's name and password were correctly entered.
                            </li>
                            <li>
                                Except in situations where Lucky Charm Sweep makes an error, a Player is not entitled to reimbursement if they lose, forget, or misplace their password. Lucky Charm Sweep does not store passwords and login information for players.
                            </li>
                            <li>
                                Any wager or other type of gamble made by the Player or through their Account can be limited or rejected by Lucky Charm Sweep.
                            </li>
                            <li>
                                If a Player files a complaint against another for any reason, Lucky Charm Sweep will look into it and take appropriate action.
                            </li>
                            <li>
                                Lucky Charm Sweep will reimburse all bets made under the Special Terms in case of a game cancellation.
                            </li>
                            <li>
                                Should a software interruption occur from any fault, Lucky Charm Sweep has the right to ask for a screenshot to identify the type of problem and determine the exact moment the service interruption interfered with the player's game.
                            </li>
                            <li>
                                At its sole discretion, Lucky Charm Sweep reserves the right to change a Player's limits on the Service and place restrictions on Players.
                            </li>
                            <li>
                                Lucky Charm Sweep has the right to check every transaction carefully to prevent money laundering. You and any other individual connected to such conduct may face criminal and/or civil legal actions.
                            </li>
                            <li>
                                Without informing you, Lucky Charm Sweep reserves the right to report suspicious transactions and provide relevant information to the right authorities. In such cases, you waive any and all rights to communication of such information that you may have wherever.
                            </li>
                            <li>
                                Lucky Charm Sweep has the sole right, at any time and without prior warning or payment, to suspend, modify, cancel, revoke, or add features to its services, temporarily or permanently. This complies with all regulatory requirements and keeps all other commitments in these Terms and Conditions.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <span className='text-white font-black text-xl'>Transactions</span>
                        <ol className='ml-6 space-y-4 py-2'>
                            <li>
                                All payments due to Lucky Charm Sweep are your responsibility. If a deposit amount has been credited to your account, you consent to refrain from impeding the payment, including by declining or attempting to reverse the payment.
                            </li>
                            <li>
                                Lucky Charm Sweep reserves the right to evaluate any Player's financial solvency using the data they provide during registration and information from third parties.
                            </li>
                            <li>
                                You understand that independent financial institutions and/or third-party electronic payment processors may verify and handle your deposits and withdrawals. You agree that you have been informed of this and give permission for third parties to verify and handle transactions on your behalf by accepting these Terms and Conditions.
                            </li>
                            <li>
                                You can only make deposits using a payment method registered in your name. Any winnings or bonuses not accepted into compliance with the Terms and Conditions will be forfeited and will face possible account closure.
                            </li>
                            <li>
                                You can only make deposits using a payment method registered in your name. Any winnings or bonuses not accepted into compliance with the Terms and Conditions will be forfeited and will face possible account closure.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <span className='text-white font-black text-xl'>Deposits</span>
                        <ol className='ml-6 space-y-4 py-2'>
                            <li>
                                Lucky Charm Sweep requires a minimum deposit of $10 before allowing a Player to play or place a wager. A minimum deposit of $10 must be made for each subsequent deposit. With fast processing, a Player can deposit money online at any moment using their crypto wallet or other available payment options on the Website.
                            </li>
                            <li>
                                When registering an account, there are no pre-set deposit limits. After completing the registration process, a Player has the option to set self-imposed limitations or limits on the total amount of bets.
                            </li>
                            <li>
                                Depositing or attempting to deposit money obtained by fraudulent, unlawful, or criminal activity into your account is prohibited. Anti-money laundering checks are required by law in addition to identity and age verification procedures to prevent these types of actions.
                            </li>
                            <li>
                                Lucky Charm Sweep monitors every transaction. We reserve the right to find out where the funds came from to meet legal and/or regulatory requirements about the source of the funds placed. If Lucky Charm Sweep receives unsatisfactory answers to these inquiries, it may, at its discretion, suspend or terminate any Account. Furthermore, the appropriate authorities will be notified of any suspicious transactions.
                            </li>
                            <li>
                                Your Lucky Charm Sweep Account deposits should correspond with the gameplay. If Lucky Charm Sweep determines that there has been misuse of the Services—for example, when money is placed and then requested to be withdrawn for reasons other than gaming—it shall have the right, at its sole discretion, to:
                                <ol className='ml-6 space-y-4 py-2'>
                                    <li>
                                        Return the corresponding deposit or deposits, take out any related expenses, cancel bets, and take out any related winnings before closing your Account.
                                    </li>
                                    <li>
                                        Withhold money until Lucky Charm Sweep has finished any checks required to meet legal, regulatory, or internal requirements.
                                        Players can use the following crypto payment methods to deposit on any available platform at Lucky Charm Sweep:<br />
                                        Bitcoin (BTC)<br />
                                        Litecoin (LTC)<br />
                                        Dogecoin (DOGE)<br />
                                        Ether (ETH)<br />
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <span className='text-white font-black text-xl'>Withdrawals</span>
                        <ol className='ml-6 space-y-4 py-2'>
                            <li>
                                All withdrawal payments will be processed within 2 - 24 hours, provided they are made under these Terms and Conditions. Please be aware that depending on which crypto payment method you use to cash out from Lucky Charm Sweep determines the withdrawal time.
                            </li>
                            <li>
                                A Player's request for a withdrawal from Lucky Charm Sweep will only be processed after
                                <ol className='ml-6 space-y-4 py-2'>
                                    <li>
                                        Confirming the identity and account of the Player.
                                    </li>
                                    <li>
                                        Providing a valid proof of deposit if requested by Lucky Charm Sweep if requested.
                                    </li>
                                    <li>
                                        Verifying that the Player hasn't started any Lucky Charm Sweep-related chargebacks.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                Lucky Charm Sweep will conduct a "Know Your Client" verification process for withdrawal transactions to prevent money laundering. The appropriate authorities will be notified of any suspicious transactions.
                            </li>
                            <li>
                                Only the payment method used to make deposits on the Websites will be used to process refunds.
                            </li>
                            <li>
                                Notwithstanding the aforementioned, withdrawal requests are subject to bonus terms and conditions and verification processes. We have the right to hold off on processing any withdrawal requests if your account is the subject of an investigation until the investigation has been completed. The terms and conditions listed in promotional emails and on our Website apply to each bonus. Before allowing any withdrawals, Lucky Charm Sweep will subtract the bonus amount and any winnings from the bonus amount if a Player requests one before fulfilling the playthrough requirement.
                            </li>
                            <li>
                                Withdrawals from the Player's Account are only allowed if Lucky Charm Sweep deems the Account to not have been involved in suspicious activity. If any suspicious activity is discovered, the Player will be contacted to get the required documentation, and the Account will be temporarily suspended while an investigation is conducted. All funds in the account will be frozen during the suspension. The Account may be canceled and undisputed funds returned to the Player, or it may be restored to regular status after the investigation is over.
                            </li>
                            <li>
                                Players can use the following crypto payment methods to withdraw their funds from Lucky Charm Sweep:<br />
                                Bitcoin (BTC)<br />
                                Litecoin (LTC)<br />
                                Dogecoin (DOGE)<br />
                                Ether (ETH)<br />
                            </li>
                        </ol>
                    </li>

                    <li>
                        <span className='text-white font-black text-xl'>Bonus Policy</span>
                        <ol className='ml-6 space-y-4 py-2'>
                            <li>
                                Only Clients who have registered and are at least 21 years old are eligible for bonuses.
                            </li>
                            <li>
                                Some bonuses and/or promotions may only be available in a few countries or jurisdictions.
                            </li>
                            <li>
                                Only the payment method used to make deposits on the Websites will be used to process refunds.
                            </li>
                            <li>
                                The release terms, conditions, and amounts of bonuses are at the exclusive discretion of Lucky Charm Sweep and are non-negotiable.
                            </li>
                            <li>
                                The specific terms and related Special Terms apply to any bonus, promotion, and/or special offer.
                            </li>
                            <li>
                                Before participating in the relevant promotion, each player agrees to read and understand the terms that apply to each bonus. Before participating in the promotion, bonus, or special offer, you will receive a comprehensive explanation of these rules from Lucky Charm Sweep.
                            </li>
                            <li>
                                All bonuses offered by Lucky Charm Sweep, unless otherwise noted, are limited to one per individual, IP address, and email address.
                            </li>
                            <li>
                                Each bonus has Wagering requirements or a Playthrough associated with it. Unless otherwise specified, awarded bonuses need to be wagered a certain number of times for any associated pending winnings to be withdrawn. When a bonus is accepted and/or credited to an account, wagering requirements are applicable.
                            </li>
                            <li>
                                If a Player has not complied with our Responsible Gambling or Anti-Money Laundering requirements, any points you have accrued may be deducted until you do.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <span className='text-white font-black text-xl'>Terms and Cancellation</span>
                        <ol className='ml-6 space-y-4 py-2'>
                            <li>
                                You can choose to terminate your account at any moment. If you want to close your account, contact Customer Service.
                            </li>
                            <li>
                                Any amount that remains in your account after it is closed, subject to any additional terms, will be mailed to you using a payment method we choose. When you close your account, any bets you made on events that haven't happened yet will still stand. When the results are revealed, you will receive the corresponding amounts if such bets win.
                            </li>
                            <li>
                                An Account will be deemed "inactive" if a Player does not log in for 12 consecutive calendar months.
                            </li>
                            <li>
                                Lucky Charm Sweep may forfeit any accumulated loyalty points in an inactive account at its discretion. Lucky Charm Sweep is not obligated to explain this decision in more detail or let the Player know before deactivating the account. After 36 months from the date of acquisition, unused reward points expire.
                            </li>
                            <li>
                                If a Player has a deposit balance on an inactive account, Lucky Charm Sweep will try to contact them and return the amount. At the end of each calendar month, if the Account is inactive, Lucky Charm Sweep has the right to levy a $5 administrative fee if, despite these efforts, there is still a deposit balance in the Account. The amount in the player's account may be used to cover this cost. There are no more charges after the amount hits zero due to the monthly charge. There will never be a negative account balance. Bonus balances will be lost, and the corresponding amount may be instantly subtracted from the Player's account balance if an account is declared inactive.
                            </li>
                            <li>
                                If a Player has a deposit balance on an inactive account, Lucky Charm Sweep will try to contact them and return the amount. At the end of each calendar month, if the Account is inactive, Lucky Charm Sweep has the right to levy a $5 administrative fee if, despite these efforts, there is still a deposit balance in the Account. The amount in the player's account may be used to cover this cost. There are no more charges after the amount hits zero due to the monthly charge. There will never be a negative account balance. Bonus balances will be lost, and the corresponding amount may be instantly subtracted from the Player's account balance if an account is declared inactive.
                            </li>
                            <li>
                                Lucky Charm Sweep reserves the right, in addition to any remedies at its disposal, to close or suspend your account, void any related bets, and/or withhold funds from your account until the issue is resolved if it has cause to believe:
                                <ol className='ml-6 space-y-4 py-2'>
                                    <li>
                                        You have multiple Lucky Charm Sweep Accounts.
                                    </li>
                                    <li>
                                        When registering for a Lucky Charm Sweep Account, you either entered false personal information or information that belonged to someone else.
                                    </li>
                                    <li>
                                        You are not of legal age for gambling.
                                    </li>
                                    <li>
                                        You misused your account in any way or permitted someone else to use it.
                                    </li>
                                    <li>
                                        You coordinated bets with other players on the same picks or played in tandem with them.
                                    </li>
                                    <li>
                                        You are discovered to be involved in any fraudulent action, including money laundering, colluding, and cheating.
                                    </li>
                                    <li>
                                        It has been established that you have manipulated the standard house edge in the games available on the Services by using a betting strategy or method.
                                    </li>
                                    <li>
                                        When using the Services, you either act on behalf of third parties or engage in dishonest or unlawful behavior.
                                    </li>
                                    <li>
                                        You violated one or more of these Terms and Conditions.
                                    </li>
                                    <li>
                                        You are operating in a way that harms how Lucky Charm Sweep does business.
                                    </li>
                                    <li>
                                        You cannot interact with the Services in any way.
                                    </li>
                                    <li>
                                        Your registered address and phone number are not verifiable by us.
                                    </li>
                                    <li>
                                        A promotion, bonus, or other deal that Lucky Charm Sweep has pushed has been abused.
                                    </li>
                                    <li>
                                        The Player and Lucky Charm Sweep are engaged in a legal battle. <br />
                                        If either party cancels this Agreement, Lucky Charm Sweep will reimburse the amount in your account minus any amounts payable to Lucky Charm Sweep and any applicable withdrawal costs. <br />
                                        Contact our customer support team for more details on how to retrieve funds from closed, blocked, and excluded accounts. <br />
                                        Our Privacy Policy governs retaining and processing all data gathered during registration. This information is kept on file for regulatory purposes, fraud prevention, and money laundering prevention. When looking into any unlawful behavior on the Websites, Lucky Charm Sweep reserves the right to fully collaborate with the authorities in charge of justice, law enforcement, and other official authorities. <br />
                                        You understand and agree that your usage of the Website and/or Services will end immediately upon termination of your account.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>

                    <li>
                        <span className='text-white font-black text-xl'>Modification of Terms and Conditions</span>
                        <ol className='ml-6 space-y-4 py-2'>
                            <li>
                                Lucky Charm Sweep reserves the right, at any time, to modify, amend, and update these Terms and the Special Terms, Rules, and Privacy Policy. Furthermore, Lucky Charm Sweep reserves the right to entirely or partially change any promotion's terms. The Player shall get notice of any modifications to the Terms and Conditions before their implementation. The Player must accept the updated terms and conditions once more before using our Services after receiving such notification.
                            </li>
                            <li>
                                Before using our services, you must agree to the most recent version of the Terms and Conditions, game rules, and Privacy Policy posted on our Websites.
                            </li>
                            <li>
                                Users should refer to the version that is now in effect, which is the one that is displayed on {`Lucky Charm Sweep's`} Website. Every Player will get a notification of any changes. Lucky Charm Sweep advises that you review the Terms and Conditions, Rules, and Privacy Policy regularly or if there is an update. Any modifications to the terms and conditions will be sent to the player in writing and accepted before being put into effect.
                            </li>
                        </ol>
                    </li>
                </ol>


            </div>
        </div>
    )
}

export default MainContent
