import React from 'react'
import Hero from '../../../components/Hero'
import ContactBg from '../../../assets/image/blogBg.png'
import ContactUsDetails from '../../../components/Contact'

const ContactUs = () => {
    return (
        <>
           <Hero title={"Contact Us"} bgImg={ContactBg} backgroundColor='#290A47' />
           <ContactUsDetails />
        </>
    )
}

export default ContactUs
