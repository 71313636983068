import React from 'react';
import Title from '../Title';
import ourProgramBg from '../../assets/image/our-progrmas.png';

const HowItWorks = () => {
    // Custom instructions for each stage
    const instructions = [
        "Sign up on our website and complete your registration.",
        "Choose your favorite game or platform from the options available.",
        "Make a minimum deposit of $10 and receive a $20 bonus as a first-time user!",
        "We’ll provide you with the platform link, username, password, and your balance.",
        "Visit the platform, log in, and start playing your favorite games to win real money.",
        "Withdraw your winnings instantly through our website."
    ];

    return (
        <div className='mt-12'>
            <Title title={"HOW IT "} continueTitle={"WORKS"} />
            <div className="flex justify-center">
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-10 place-items-center'>
                    {instructions.map((instruction, index) => (
                        <div 
                            key={index} 
                            style={{ backgroundImage: `url(${ourProgramBg})` }}
                            className='how-it-works-card relative flex flex-col gap-5 items-center justify-center border border-[#FFDD15] py-3 px-3 pt-5 text-xl rounded-2xl h-[200px] w-[250px] transition-transform duration-300 hover:scale-105 hover:shadow-2xl'
                        >
                            <div className="overlay absolute inset-0 bg-black opacity-0 transition-opacity duration-300 rounded-2xl hover:opacity-30"></div>
                            <p className='text-white text-xl font-semibold z-10'>Stage {index + 1}</p>
                            <p className='text-[#CACACA] h-full text-center z-10'>
                                {instruction}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
