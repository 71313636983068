import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';

const SocialReward = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => !!state.auth.token); // Check for token existence


  useEffect(() => {
    const encodedUserId = searchParams.get('user');
    const userId = atob(encodedUserId);
    const action = searchParams.get('action');
    const timestamp = searchParams.get('t');

    const fetchData = async () => {
      try {
        const apiUrl = `https://api.luckycharmsweep.com/api/v2/dfjfngjn/social-reward.php?user=${userId}&action=${action}&t=${timestamp}`;

        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        const responseText = await response.text();
        let responseData;
        try {
          responseData = JSON.parse(responseText.trim());
        } catch (error) {
          console.error('Error parsing JSON:', error);
          console.log('Response Text:', responseText);
          if (isLoggedIn) {
            toast.error('An error occurred while processing the reward.');
          }
          return;
        }

        if (responseData.status && responseData.status.code === '1') {
          if (isLoggedIn) {
            toast.success('Reward added successfully!');
          }
        } else {
          if (isLoggedIn) {
            const errorMessage = responseData.status?.message || 'Failed to add reward. Please try again later.';
            toast.error(errorMessage);
          }
        }

      } catch (error) {
        console.error('Error adding reward:', error);
        if (isLoggedIn) {
          toast.error('An error occurred.');
        }
      } finally {
        navigate('/home');
      }
    };

    if (userId && action && timestamp) {
      fetchData();
    } else {
      if (isLoggedIn) {
        toast.error('Invalid request.');
      }
      navigate('/home');
    }
  }, [searchParams, navigate, isLoggedIn]);

  return null;
};

export default SocialReward;