import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getImageUrl } from '../../../utils/getImageUrl'; // Import the helper function

const PlayingNow = () => {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false); // State to manage image loading
  const divRef = useRef(null); // Reference to the div element

  const backgroundImageUrl = getImageUrl('/assets/image/Intro-test.png');

  // Use Intersection Observer to implement lazy loading
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsLoaded(true); // Load the image when the component is in view
          observer.disconnect(); // Disconnect the observer after loading
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the component is visible
    );

    if (divRef.current) {
      observer.observe(divRef.current); // Observe the div element
    }

    return () => {
      if (divRef.current) {
        observer.disconnect(); // Cleanup the observer on unmount
      }
    };
  }, []);

  const handleClick = () => {
    navigate('/deposit'); // Redirect to the Deposit page
  };

  return (
    <div
      ref={divRef} // Attach the ref to the div element
      className="mt-12 lgs:mt-0 text-yellow-400 py-8 text-center w-full"
      style={{
        backgroundImage: isLoaded ? `url(${backgroundImageUrl})` : 'none', // Load image only when in view
        backgroundPosition: 'bottom',
        backgroundSize: 'cover',
      }}
    >
      <h1 className="text-xl lgs:text-[27px] font-semibold mb-4">
        Get Paid Fast, Stress-Free! Seamless withdrawals deliver your money instantly!
      </h1>
      <button
        className="bg-yellow-400 text-gray-900 px-6 py-2 rounded-lg text-lg font-semibold hover:bg-[#ffd21e] transition-colors shadow-custom"
        onClick={handleClick} // Attach the click handler
      >
        Start Playing Now
      </button>
    </div>
  );
};

export default PlayingNow;
