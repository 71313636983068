import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  games: [], // This will store the game data
  cart: [], // Add a cart state to store games added to the cart
};

const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    setGames: (state, action) => {
      state.games = action.payload;
    },
    updateGame: (state, action) => {
      const updatedGame = action.payload;
      const index = state.games.findIndex(game => game.id === updatedGame.id);

      if (index !== -1) {
        // Check if the current state game has is_game_add === true and the updatedGame has is_game_add === false
        if (state.games[index].is_game_add === true && updatedGame.is_game_add === false) {
          // Update the existing game's is_game_add to true
          state.games[index].is_game_add = true;
        } else {
          // Otherwise, update the entire game object
          state.games[index] = updatedGame;
        }
      } else {
        // If game not found in state, push it as a new entry
        state.games.push(updatedGame);
      }
    },
    addToCart: (state, action) => {
      const { id, quantity = 10 } = action.payload; // Default quantity is 10 if not provided
      const gameIndex = state.games.findIndex(game => game.id === id);
      const cartIndex = state.cart.findIndex(game => game.id === id);

      if (gameIndex !== -1) {
        state.games[gameIndex].is_game_add = true;
      }

      if (cartIndex !== -1) {
        // Update quantity if game already in cart
        state.cart[cartIndex].quantity = quantity;
      } else {
        // Add new game to cart
        state.cart.push({ ...state.games[gameIndex], quantity });
      }
    },
    updateCartQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      const cartIndex = state.cart.findIndex(game => game.id === id);

      if (cartIndex !== -1) {
        state.cart[cartIndex].quantity = quantity;
      }
    },
    removeFromCart: (state, action) => {
      const { id } = action.payload;
      const gameIndex = state.games.findIndex(game => game.id === id);

      if (gameIndex !== -1) {
        state.games[gameIndex].is_game_add = false;
      }

      state.cart = state.cart.filter(game => game.id !== id);
    },
    resetGamesState: (state) => {
      // Reset the games state to the initial state
      state.games = [];
      state.cart = [];
    },
  },
});

export const { setGames, updateGame, addToCart, updateCartQuantity, removeFromCart, resetGamesState } = gamesSlice.actions;

export default gamesSlice.reducer;
