import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { CopyIcon } from '../../utils/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { ApiHandler } from '../../helper/ApiHandler';
import { useNavigate } from 'react-router-dom';

const Referral = () => {
    const referralCode = useSelector(state => state.auth.user);
    const [referrals, setreferrals] = useState([]);
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        fetchReferrals();
    }, []);

    const fetchReferrals = async () => {
        try {
            const response = await ApiHandler('/referral.php', 'POST', undefined, token, dispatch, navigate);
            setreferrals(response?.data?.data);
        } catch (error) {
            console.log(error);
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(referralCode.referral_code);
        toast.success('Referral code copied to clipboard!');
    };

    return (
        <div className="flex justify-center items-center">
            <div className="w-full max-w-md main-dot-bg p-10 rounded-lg shadow-lg">
                <h1 className="text-3xl font-semibold text-center mb-4 text-[#e2e8f0]">Referrals</h1>
                <div className="flex flex-col xs:flex-row justify-center items-center mb-4">
                    <span className="text-lg font-medium text-[#cbd5e1]">Referral Code: </span>
                    <div>
                        <span className="text-lg font-semibold ml-2 text-[#FFDD15]">{referralCode.referral_code}</span>
                        <button
                            onClick={copyToClipboard}
                            className="ml-2 p-1 rounded text-[#38bdf8] hover:text-[#0ea5e9] focus:outline-none bg-transparent"
                        >
                            <CopyIcon className="w-4 fill-white bg-transparent" />
                        </button>
                    </div>
                </div>
                {
                    referrals && (
                        <div className="overflow-hidden rounded-lg border border-[#fff]">
                            <table className="min-w-full ">
                                <thead>
                                    <tr className='bg-[#290A47]'>
                                        <th className="py-3 px-4 text-left font-medium text-white">Referral Name</th>
                                        <th className="py-3 px-4 text-left font-medium text-white">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {referrals.map((referral, index) => (
                                        <tr key={index} className="border-t bg-[#0E0E0E] border-[#fff]">
                                            <td className="py-3 px-4 text-white font-semibold">{referral.name}</td>
                                            <td className="py-3 px-4">
                                                <span
                                                    className={`inline-flex items-center justify-center px-2 py-1 text-sm font-semibold leading-none rounded-full ${referral.status === '0' ? 'text-[#F8924F]' : 'text-[#4AFFA9]'}`}>
                                                    {referral.status === '0' ? 'Pending' : 'Completed'}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default Referral;
