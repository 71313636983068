import React, { useEffect, useState } from 'react';
import Hero from '../../../components/Hero';
import DepositBg from '../../../assets/image/blogBg.png';
import MainContent from '../../../components/deposit/MainContent';
import Loading from '../../../components/Common/Loading';
import { updateGame } from '../../../redux/slice/gamesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ApiHandler } from '../../../helper/ApiHandler';

const Deposit = () => {
    const [counters, setCounters] = useState([]);
    const [loading, setLoading] = useState(true);
    const games = useSelector((state) => state.games.games);

    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fetchPlatforms = async () => {
        try {
            const response = await ApiHandler('/get_game_list.php', 'POST', { "gameID": "" }, undefined, dispatch, navigate);
            if (response.data.status.code === "1") {
                const data = response.data.data;
                data.forEach((game) => {
                    const updatedGame = {
                        ...game,
                        // Add custom logic here if needed, e.g., setting is_game_add to false for some games
                        is_game_add: game.is_game_add ? game.is_game_add : false,
                        quantity: 10,
                        platforms_id: game.platforms_id ? game.platforms_id : 0
                    };
                    dispatch(updateGame(updatedGame));
                });
                setCounters(new Array(response.data.data.length).fill(10));
            }
        } catch (error) {
            console.error('Error fetching depositItems:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchPlatforms();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <Hero bgImg={DepositBg} title={"DEPOSIT NOW"} />
            <MainContent depositItems={games} counters={counters} setCounters={setCounters} fetchPlatforms={fetchPlatforms} />
        </>
    );
}

export default Deposit;
