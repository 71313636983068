import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getImageUrl } from '../../utils/getImageUrl';
import logo from '../../assets/image/logo.png';
import { 
    AndroidIcon, 
    BitcoinIcon, 
    LitecoinIcon, 
    DogecoinIcon, 
    CreditCardIcon, 
    FacebookIcon, 
    InstagramIcon, 
    QuoraIcon, 
    RedditIcon, 
    TelegramIcon, 
    TwentyoneIcon, 
    TwitterIcon 
} from '../../utils/Icons';

const Footer = () => {
    const [isBgLoaded, setIsBgLoaded] = useState(false);
    const divRef = useRef(null);

    const backgroundImageUrl = getImageUrl('/assets/image/footer_main.png');

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setIsBgLoaded(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );

        if (divRef.current) {
            observer.observe(divRef.current);
        }

        return () => {
            if (divRef.current) {
                observer.disconnect();
            }
        };
    }, []);

    return (
        <>
            <div className="relative h-full w-full py-10">
                <div
                    ref={divRef}
                    style={{
                        backgroundImage: isBgLoaded ? `url(${backgroundImageUrl})` : 'none',
                    }}
                    className="absolute inset-0 h-full w-full bg-cover bg-bottom bg-no-repeat opacity-20"
                ></div>
                <div className="relative z-10">
                    <div className="container mx-auto">
                        <div className="flex items-start justify-between flex-wrap gap-10">
                            <div className="w-full md:grid md:grid-cols-4 gap-6 text-white mt-6 sm:grid sm:grid-cols-2">
                                {/* Logo and Description */}
                                <div>
                                    <Link to="/home" className="flex justify-start gap-1">
                                        <img src={logo} className="h-[38.5px] w-10 object-contain" alt="logo" loading="lazy" />
                                        <div className="flex flex-col gap-1">
                                            <h1 className="leading-none text-white">Lucky Charm</h1>
                                            <h2 className="leading-none text-[#FFDD15]">SWEEP</h2>
                                        </div>
                                    </Link>
                                    <div className="mt-2">
                                        <h3 className="text-lg font-bold text-white">
                                            Play, Win, Withdraw – Instantly! Your winnings, your way!
                                        </h3>
                                        <p className="text-sm text-[#CACACA] mt-2">
                                            We support users in creating accounts, recharging, and withdrawing funds. All platforms are licensed and operate independently. We do not host or operate our own casino games.
                                        </p>
                                    
                                    </div>
                                    <div className="flex flex-wrap gap-3 mt-2">
                                        <Link to="https://www.facebook.com/luckycharmsweep/"><FacebookIcon /></Link>
                                        <Link to="#"><InstagramIcon /></Link>
                                        <Link to="#"><TwitterIcon /></Link>
                                        <Link to="#"><TelegramIcon /></Link>
                                    </div>
                                    <br />
                                    <strong>Phone:</strong><span>+17035406017</span>
                                    <p>Strictly for users aged 21 and above. 
                                    <TwentyoneIcon /></p>
                                </div>

                                {/* Useful Links */}
                                <div>
                                    <h4 className="font-bold mb-3">Useful Links</h4>
                                    <ul className="flex flex-col gap-2">
                                        <li><Link to="/faq">FAQ</Link></li>
                                        <li><Link to="/contact-us">Contact</Link></li>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        <li><Link to="/terms-of-service">Terms of Service</Link></li>
                                    </ul>
                                    <br />
                                </div>

                                {/* Pages */}
                                <div>
                                    <h4 className="font-bold mb-3">Pages</h4>
                                    <ul className="flex flex-col gap-2">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/about">About</Link></li>
                                        <li><Link to="/blog">Blog</Link></li>
                                        <li><Link to="/deposit">Deposit Now</Link></li>
                                        <li><Link to="/referral/program">Referrals Program</Link></li>
                                    </ul>
                                    <br />
                                </div>

                                {/* Game Types */}
                                <div>
                                    <h4 className="font-bold mb-3">Type</h4>
                                    <ul className="flex flex-col gap-2">
                                        <li><Link to="/games?category=keno">Keno</Link></li>
                                        <li><Link to="/games?category=slots">Slots</Link></li>
                                        <li><Link to="/games?category=fish%20games">Fish Games</Link></li>
                                        <li><Link to="/games?category=arcade%20games">Arcade Games</Link></li>
                                        <li><Link to="/games?category=table%20games">Table Games</Link></li>
                                        <li><Link to="/games?category=bingo">Bingo</Link></li>
                                    </ul>
                                </div>
                            </div>

                            {/* Supported Currencies and SSL Info */}
                            <div className="flex flex-col items-center mt-8 text-white">
                                <h4 className="font-bold mb-3">Supported Currencies</h4>
                                <div className="flex gap-3">
                                    <BitcoinIcon />
                                    <LitecoinIcon />
                                    <DogecoinIcon />
                                    <CreditCardIcon />
                                </div>
                                <p className="text-sm mt-2">SSL Secured Transactions</p>
                                
                                <p className="text-xs mt-2 text-[#CACACA]">
                                    &copy; 2024 Lucky Charm Sweep. All rights reserved. Lucky Charm Sweep supports creating user accounts on licensed platforms. We facilitate deposits and withdrawals for users aged 21 and over.
                                </p>
                                <p className="text-xs mt-2 text-[#CACACA]">
                                    Disclaimer: We do not operate any casino games. All gaming platforms are independently owned and licensed.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

         
        </>
    );
};

export default Footer;
