import React from 'react'
import Hero from '../../../components/Hero'
import SupportBg from '../../../assets/image/blogBg.png'
import SupportDetails from '../../../components/Support/index'

const Support = () => {
  return (
    <>
      <Hero title={"Support"} bgImg={SupportBg} backgroundColor='#290A47' />
      <SupportDetails />
    </>
  )
}

export default Support
