import React from 'react'
import FaqBg from '../../../assets/image/blogBg.png'
import Hero from '../../../components/Hero'
import FaqContent from '../../../components/Faq/index'

const Faq = () => {
  return (
    <>
      <Hero bgImg={FaqBg} title={"FAQ"} />
      <FaqContent />
    </>
  )
}

export default Faq
