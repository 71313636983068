import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GamesDescriptionBg from '../../../assets/image/gamesDescriptionBg.png';
import MainContent from '../../../components/gamesDescription/MainContent';
import Hero from '../../../components/Hero';
import Loading from '../../../components/Common/Loading';
import { ApiHandler } from '../../../helper/ApiHandler';
import { useDispatch, useSelector } from 'react-redux';

const DiamondShotGame = () => {
    const { game_slug } = useParams();  // Updated to use game_slug from the URL
    console.log("Game Slug:", game_slug); // Log game_slug to the console

    const [gameDetails, setGameDetails] = useState(null);
    const [loading, setLoading] = useState(true); // Additional loading state
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGameDetails = async () => {
            try {
                const response = await ApiHandler('/get_game_list.php', 'POST', { game_slug: game_slug }, undefined, dispatch, navigate);
                
                if (response.data.status.code === '1' && response.data.data.length > 0) {
                    setGameDetails(response.data.data[0]);
                } else {
                    console.error('Game not found');
                    setGameDetails(null); // Set to null if no game found
                }
            } catch (error) {
                console.error('Failed to fetch game details:', error);
            } finally {
                setLoading(false); // Set loading to false once the fetch is complete
            }
        };

        fetchGameDetails();
    }, [game_slug, dispatch, navigate]);

    if (loading) {
        return <Loading />;
    }

    if (!gameDetails) {
        navigate('/deposit'); // Automatically forward to the deposit page

        return (
            <div className="text-center text-white py-10">
                <h1>Game not found</h1>
                <p>Sorry, the game you're looking for does not exist or may have been removed.</p>
            </div>
        );
    }

    return (
        <>
            <Hero title={gameDetails?.game_name} bgImg={GamesDescriptionBg} backgroundColor='#290A47' />
            <MainContent gameDetails={gameDetails} />
        </>
    );
};

export default DiamondShotGame;
