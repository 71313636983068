import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CrosBawIcon from '../../assets/image/crossbow.png';
import headerMobileLogo from '../../assets/image/headerMobileLogo.png';
import { logout, setLevelDataState, UPDATE_BALANCE } from '../../redux/slice/authSlice';
import { CartIcon2, CrossIcon, MenuIcon, WalletIcon } from '../../utils/Icons';
import WeeklyChallengeIcon from '../../assets/image/weekly-challenge-icon.png';
import NotificationIcon from "../../assets/image/notification.png";
import WeeklyPopupBgImg from '../../assets/image/weeklyPopupBgImg.png';
import { resetGamesState } from '../../redux/slice/gamesSlice';
import { ApiHandler } from '../../helper/ApiHandler';
import { clearCouponCode } from '../../redux/slice/couponSlice';
// ... other imports
import Lottie from 'lottie-react'; // Import Lottie
import rewardAnimation from '../../assets/animations/ic_invest.json'; // Import your animation
import TaskModal from '../../components/Layout/TaskModal'; // Import TaskModal


const HeaderMobile = () => {
    const [open, setOpen] = useState(false);
    const [taskModalOpen, setTaskModalOpen] = useState(false); // Add state for modal

    const openTaskModal = () => setTaskModalOpen(true); // Function to open modal
    const closeTaskModal = () => setTaskModalOpen(false); 
    const [walletDropdownVisible, setWalletDropdownVisible] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector(state => state.auth.token);
    const userData = useSelector(state => state.auth.user);
    const [today, setToday] = useState('');
    const [weeklyChallengeData, setWeeklyChallengeData] = useState([]);
    const location = useLocation();

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const { activeLevel, main_balance, bonus_balance, total_count } = useSelector(state => state.auth);
    // Find level 
    const [progress, setProgress] = useState(0);

    const FindGetActiveLevel = async () => {
        // Fetch bounus level data
        try {
            const response = await ApiHandler('/bounus_level.php', 'POST', undefined, token, dispatch, navigate);
            if (response.data && response.data.status.code === 1) {
                const enrichedData = response.data.data; // Assume enrichData is a function to process data
                dispatch(setLevelDataState(enrichedData));
            } else {
                console.error('Failed to fetch bonus levels: Invalid response');
            }
        } catch (error) {
            console.error('Failed to fetch bonus levels:', error);
        }
    }

    // Fetch wallet data first
    const getGeneralWalletData = async () => {
        try {
            const response = await ApiHandler('/user_balance.php', 'GET', undefined, token, dispatch, navigate);
            if (response.status === 200) {  // Check if the response is OK
                const { main_balance, bonus_balance, total_deposit, promotion_count, system_count, total_count } = response.data.data;
                dispatch(UPDATE_BALANCE({ main_balance, bonus_balance, total_deposit, promotion_count, system_count, total_count }));

                // Call fetchData() after getGeneralWalletData() succeeds
                fetchData();
            } else {
                console.error('Failed to fetch wallet data: Invalid response');
            }
        } catch (error) {
            console.error('Failed to fetch wallet data:', error);
        }
    };

    // Call fetch data only after wallet data is fetched
    const fetchData = async () => {
        try {
            const response = await ApiHandler('/weekly_challenge_bonus_percentage.php', 'GET', undefined, token, dispatch, navigate);
            if (response?.data?.status?.code === 1) {
                setWeeklyChallengeData(response?.data?.data || []);
            } else {
                console.error('Error fetching weekly challenge data:', response?.data?.status?.message);
            }
        } catch (error) {
            console.error('Error fetching weekly challenge data:', error);
        }
    };

    useEffect(() => {
        if (token) {
            getGeneralWalletData();  // First call getGeneralWalletData()
        }
    }, [token, location]);

    useEffect(() => {
        if (!activeLevel && token) {
            FindGetActiveLevel();
        }
    }, [activeLevel, token]);

    useEffect(() => {
        if (main_balance && activeLevel) {
            const validMainBalance = main_balance === 0 ? 1 : main_balance;
            const progressCount = (validMainBalance / activeLevel.max_deposit_limit) * 100;
            setProgress(progressCount);
        }
    }, [main_balance, activeLevel]);

    useEffect(() => {
        const todayDay = new Date().toLocaleDateString('en-US', { weekday: 'long' });
        setToday(todayDay);
    }, []);

    const handleLinkClick = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        dispatch(logout());
        dispatch(resetGamesState());
        dispatch(clearCouponCode());
        navigate("/login");
        setOpen(false);
    };

    const toggleWalletDropdown = () => {
        setWalletDropdownVisible(!walletDropdownVisible);
        setOpen(false);
    }

    const openModal = () => {
        setIsOpen(true);
    };


    const closeModal = () => {
        setIsOpen(false);
    };

    // Refs to detect clicks outside
    const walletDropdownRef = useRef(null);

    // Close dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                walletDropdownRef.current && !walletDropdownRef.current.contains(event.target)
            ) {
                setWalletDropdownVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <header className={`w-full bg-[#222222] ${open && 'h-[100vh] overflow-y-scroll fixed z-50 text-white'}`}>
            <div className='flex justify-between flex-col items-center '>
                <div className='flex justify-between items-center w-full bg-[#0E0E0E] sm:px-5 px-3'>
                    <Link to="/" onClick={handleLinkClick}>
                        <img src={headerMobileLogo} alt="logo" />
                    </Link>
                    <div className="flex items-center gap-3">
                    <div className="flex relative items-center gap-1">
              <Lottie 
                animationData={rewardAnimation} 
                loop 
                className="w-10 h-10 cursor-pointer" 
                onClick={openTaskModal} // Open modal on click
              />
            </div>
            <TaskModal 
              taskModalOpen={taskModalOpen} 
              closeTaskModal={closeTaskModal} 
              isLoggedIn={!!token} 
            /> 
                        {token && <>
                            <div className="relative ">
                                <div className="flex items-center gap-1 cursor-pointer" onClick={toggleWalletDropdown}>
                                    <WalletIcon className="w-5 h-5 fill-[#FFDD15]" />
                                </div>
                                {walletDropdownVisible && (
                                    <div ref={walletDropdownRef} className="absolute -left-48 mt-4 w-[250px] bg-black text-white rounded-lg shadow-lg p-4 border border-[#FFDD15] z-50">
                                        <div className='flex flex-col items-start xs:flex-row xs:justify-between sx:items-center p gap-2 xs:gap-0'>
                                            <p className='font-bold text-sm'>General Wallet</p>
                                            <p className='text-sm font-medium'>Total:${main_balance + bonus_balance || 0}</p>
                                        </div>
                                        <div className='flex flex-col gap-3'>
                                            <div className="p-5 border-b flex justify-between items-center border-[#444] bg-[#1e1e1e] mt-4 rounded-lg">
                                                <span className="block text-sm">Main</span>
                                                <span className="block text-sm font-bold">${main_balance || 0}</span>
                                            </div>
                                            <div className="p-5 flex justify-between items-center bg-[#1e1e1e] rounded-lg">
                                                <span className="block text-sm">Bonus</span>
                                                <span className="block text-sm font-bold">${bonus_balance || 0}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>


                        </>}
                        {token && (
                            <>
                                <div className="relative">
                                    <div
                                        className='flex items-center justify-center w-10 h-10 z-[50] rounded-full relative cursor-pointer'
                                    >
                                        <div className=' rounded-full border-[#FFDD15] border-2 h-9 w-9 flex items-center justify-center font-bold text-[#FFDD15]'>
                                            {userData?.username?.substring(0, 2).toUpperCase()}
                                        </div>
                                    </div>
                                </div>

                            </>
                        )}
                        {open ? (
                            <CrossIcon className="fill-white w-6 h-6" onClick={() => setOpen(false)} />
                        ) : (
                            <MenuIcon onClick={() => setOpen(true)} className="fill-white" />
                        )}

                    </div>
                </div>

                {
                    token && (
                        <>
                            <div className='bg-[#0E0E0E] w-full sm:px-5 px-3 flex justify-between items-center py-3'>
                                <div className='flex gap-2'>
                                    <img src={WeeklyChallengeIcon} alt="Weekly Challenge" className='object-contain' onClick={openModal} />
                                    <Link to="/bonuses/level" className="newNav_onlyDesktop__d4BrB">
                                        <div className="newNav_progress__SFIF_">
                                            <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: '51px', height: '50px', background: 'none', opacity: '1', border: '0px', margin: '0px', padding: '0px', position: "relative" }}>
                                                <img
                                                    src={CrosBawIcon}
                                                    decoding="async"
                                                    data-nimg="fixed"
                                                    style={{ position: "absolute", inset: "0px", boxSizing: "border-box", padding: "0px", border: 'none', margin: 'auto', display: 'block', width: '0px', height: '0px', minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: "100%" }} />
                                            </span>
                                            <div className="newNav_levelBar__dD693 text-white">
                                                <span className="newNav_textItself__xsBoP">
                                                    {activeLevel ? activeLevel.id : '1'} LEVEL
                                                </span>
                                                <div className="newNav_progress__bar__iBAcL" style={{ width: `${progress}%`, maxWidth: '100%' }}>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className='flex gap-2'>
                                    <Link to="/cart" className="flex items-center gap-1">
                                        <CartIcon2 className="w-7 h-7 fill-[#FFDD15]" />
                                    </Link>
                                    <Link to="/user/message/system" className="flex relative items-center gap-1">
                                        <img src={NotificationIcon} alt="" className='relative z-20' />
                                        <span className='absolute -right-2 -top-2 text-white'>
                                            {total_count && total_count !== 0
                                                ? total_count
                                                : ''}
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </>
                    )
                }

            </div>

            {open && (
                <>
                    <div className='text-white flex flex-col justify-center items-center w-full gap-3 mt-5'>
                        <Link className='text-xl' to="/home" onClick={handleLinkClick}>Home</Link>
                        <hr className='w-full border border-white/5' />
                        <Link className='text-xl' to="/blog" onClick={handleLinkClick}>Blog</Link>
                        <hr className='w-full border border-white/5' />
                        <Link className='text-xl' to="/cart" onClick={handleLinkClick}>Cart</Link>
                        <hr className='w-full border border-white/5' />
                        <Link className='text-xl' to="/deposit" onClick={handleLinkClick}>Deposit</Link>
                        <hr className='w-full border border-white/5' />
                        <Link className='text-xl' to="/dashboard" onClick={handleLinkClick}>Dashboard</Link>
                        <hr className='w-full border border-white/5' />
                        <Link className='text-xl' to="/profile" onClick={handleLinkClick}>Profile</Link>
                        <hr className='w-full border border-white/5' />
                        <Link className='text-xl' to="/contact-us" onClick={handleLinkClick}>Contact Us</Link>
                        <hr className='w-full border border-white/5' />
                        <Link className='text-xl ' to="/about" onClick={handleLinkClick}>About Us</Link>
                        {token && (
            <>
                <hr className='w-full border border-white/5' />
                <Link className='text-xl pb-5' onClick={() => {
                    handleLogout();
                    setOpen(false);
                }}>Logout</Link>
            </>
        )}
                    </div>

                    {
                        !token && (
                            <div className='flex  bg-[#0E0E0E] p-5 gap-5 items-center  w-full'>
                                <Link to={"/login"} className='bg-[#FFDD15] text-black py-2 w-[150px] text-center font-bold' onClick={() => {
                                    handleLinkClick()
                                }} >
                                    Sign In
                                </Link>
                                <Link to={"/sign-up"} className='bg-[#290A47] border border-white/50 py-2 w-[150px] text-center font-bold' onClick={() => {
                                    handleLinkClick()
                                }} >
                                    Sign Up
                                </Link>
                            </div>
                        )
                    }
                </>
            )}

            {isOpen && (
                <div className="fixed inset-0 w-full flex items-center justify-center z-50 bg-black/80 bg-opacity-50">
                    <div className="relative  bg-black text-white rounded-lg w-full md:w-[600px] max-h-screen overflow-y-auto h-[90vh] my-auto">
                        {/* Close Button */}
                        <button
                            className="absolute top-2 right-2 text-white font-bold text-xl bg-transparent"
                            onClick={closeModal}
                        >
                            &times;
                        </button>

                        <div className="h-full">
                            {/* Header with background */}
                            <div
                                style={{
                                    backgroundImage: `url(${WeeklyPopupBgImg})`,
                                    height: '100px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundSize: 'cover',
                                }}
                            >
                                <h2 className="text-center text-2xl font-bold text-yellow-400">
                                    WEEKLY CHALLENGE
                                </h2>
                            </div>

                            {/* Scrollable content */}
                            <div className="p-[1rem]">
                                {daysOfWeek.map((day, index) => (
                                    <div key={day} className="flex flex-col md:flex-row  mt-3 md:gap-3">
                                        {/* Day container */}
                                        <div
                                            className={`p-2 w-[120px] md:w-[150px] text-lg rounded-lg text-center border-dashed border-2 border-yellow-400 
                                    ${today === day ? 'bg-yellow-400 text-black font-bold' : ''}`}
                                        >
                                            {day}
                                        </div>

                                        {/* Bonus Text */}
                                        <div className="bg-purple-900 text-white p-3 text-base rounded-lg text-center border-2 border-purple-400 w-full mt-2 md:mt-0">
                                            Make deposit and earn additional {weeklyChallengeData[index]?.bonus_percentage || 5}% bonus
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </header>
    );
}

export default HeaderMobile;
