import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import authReducer from './slice/authSlice';
import gamesReducer from './slice/gamesSlice';
import couponReducer from './slice/couponSlice';
import { thunk } from 'redux-thunk';


const persistConfig = {
  key: 'luckcharmseep',
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  games: gamesReducer,
  coupon: couponReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: true
});

export const persistor = persistStore(store);
