import React from 'react'
import MainContent from '../../../components/blog/MainContent'
import BlogBg from '../../../assets/image/blogBg.png'
import Hero from '../../../components/Hero'
import { useParams } from 'react-router-dom'

const Blog = () => {
  const { id } = useParams();
  return (
    <>
      <Hero bgImg={BlogBg} title={"BLOG"} />
      <MainContent id={id}/>
    </>
  )
}

export default Blog
