import React, { useEffect, useState } from 'react'
import { InfoIcon } from '../../../utils/Icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import secure from '../../../assets/image/secure.png';
import Loading from '../../../components/Common/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { ApiHandler } from '../../../helper/ApiHandler';

const DashboardTransactionView = () => {
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, order_id } = useParams();
    const [game_details, setGame_details] = useState([]);
    const [orderId, setOrderId] = useState('');
    const [payment_status, setPayment_status] = useState('');
    const [rejection_note, setRejection_note] = useState('');
    const [payment_type, setPayment_type] = useState('');
    const [loading, setLoading] = useState(true);

    const FetchDepositDetails = async () => {
        try {
            setLoading(true);
            const response = await ApiHandler('/wallet_payment_transaction_detail.php', 'POST', {
                id: id,
                order_id: order_id,
            }, token, dispatch, navigate);

            if (response.data.status.code === "1") {
                setGame_details(response.data.data.game_details);
                setOrderId(response.data.data.order_id);
                setRejection_note(response.data.data.rejection_note);
                setPayment_status(response.data.data.payment_status);
                setPayment_type(response.data.data.game_details.payment_type);
                setLoading(false);
            } else {
                setGame_details([]);
                setOrderId('');
                setRejection_note('');
                setPayment_status('');
                setPayment_type('');
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (id && order_id) {
            FetchDepositDetails();
        }
    }, [id, order_id])

    if (loading) {
        return <Loading />;
    }
    return (
        <div className="container mx-auto text-white mt-16 md:mt-36 px-4">
            <h1 className="text-2xl md:text-3xl font-bold mb-4">Order #{orderId}</h1>
            {
                rejection_note && (
                    <div className="text-white p-4 rounded mb-6 relative py-6 md:py-8"
                        style={{ backgroundColor: "#ff5f66" }}
                    >
                        <InfoIcon className="absolute top-2 left-2" />

                        <p className="font-bold mt-2">
                            {rejection_note}
                        </p>
                    </div>
                )
            }
            <p className="mb-4">
                Please send the <span className="font-bold text-red-500 underline">exactly</span> indicated amount of bitcoin to the wallet.
            </p>
            <p className="mb-4">
                You can make the payment by manually entering the address or simply scanning the QR code with your Bitcoin wallet. Once you issue payment, your order will be automatically processed.
            </p>

            <div className="text-white p-4 rounded mb-6 relative py-6 md:py-8"
                style={{ backgroundColor: payment_type === "BTC" ? "#f7931a" : payment_type === "LTC" ? "#345D9D" : payment_type === "DOGE" ? "#C2A633" : payment_type === "Wallet" ? "#8cc43e" : payment_type === "CashApp" ? '#FF9085' : "#ff5f66" }}
            >
                <InfoIcon className="absolute top-2 left-2" />
                <p>
                    If you do not send the exactly shown amount (not including the transaction fee), processing time for your order may take longer due to the manual review process.
                </p>
                <p className="font-bold mt-2">
                    You may have to pay the missing part of your deposit after review.
                </p>
            </div>

            <hr className="border-white/15 border" />

            {
                game_details && (
                    <div className="flex flex-col lg:flex-row w-full gap-5">
                        <div className="bg-gray-800 p-4 rounded my-6 flex-[2] h-full overflow-auto"
                            style={{
                                borderColor: 'rgba(255, 255, 255, 0.16)',
                                borderWidth: '1px'
                            }}
                        >
                            <div className="overflow-x-auto">
                                <table className="min-w-full table-auto">
                                    <thead>
                                        <tr className='text-base md:text-lg font-semibold'>
                                            <th className="p-2 text-start">NAME</th>
                                            <th className="p-2 text-start">Amount</th>
                                            <th className="p-2 text-start">Bonus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border px-2 py-4">Wallet</td>
                                            <td className="border px-2 py-4">{game_details?.amount}</td>
                                            <td className="border px-2 py-4">No Bonus</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="bg-gray-800 p-4 rounded my-6 flex-1 h-auto"
                            style={{
                                borderColor: 'rgba(255, 255, 255, 0.16)',
                                borderWidth: '1px'
                            }}>
                            <button type="button"
                                className="w-full chakra-button css-ct8v74 !text-white cursor-not-allowed"
                                style={{ backgroundColor: payment_type === "BTC" ? "#f7931a" : payment_type === "LTC" ? "#345D9D" : payment_type === "DOGE" ? "#C2A633" : payment_type === "Wallet" ? "#8cc43e" : payment_type === "CashApp" ? '#FF9085' : "#ff5f66" }}
                                disabled
                            >
                                Pay with {payment_type === "BTC" ? "Bitcoin" : payment_type === "LTC" ? "Litecoin" : payment_type === "DOGE" ? "Dogecoin" : payment_type === "Wallet" ? "Wallet" : payment_type === "CashApp" ? 'CashApp' : "Bonus"}
                            </button>
                            <p className='text-sm font-medium text-white mt-1'>
                                By placing this order, you agree with our
                                <Link to="/privacy-policy" className='text-blue-400 pl-1 pr-1 underline'>
                                    Privacy Policy</Link>
                                and
                                <Link to="/terms-of-service" className='text-blue-400 pl-1 pr-1 underline'>
                                    Terms Of Use
                                </Link>
                            </p>

                            <div className='my-4'>
                                <img src={secure} alt="Secure Payment" />
                            </div>

                            <h2 className="font-bold text-lg">Bonuses & Discounts</h2>
                            {game_details?.promocode ? (
                                <>
                                    <hr className="border-white/5 border my-3" />
                                    <div className="mb-2 flex justify-between items-center font-bold">
                                        <p>Promo Code</p>
                                        <span className="text-sm text-white bg-blue-500 px-2 py-1 rounded-md">
                                            {game_details?.promocode}
                                        </span>
                                    </div>
                                </>
                            ) : ""}

                            {game_details?.promocodeDiscount ? (
                                <div className="mb-2 flex justify-between font-bold">
                                    <p>Promo Code Discount</p>
                                    <p>{game_details?.promocodeDiscount}%</p>
                                </div>
                            ) : null}

                            {game_details?.totalamount ? (
                                <div className="mb-2 flex justify-between font-bold">
                                    <p>Original Total</p>
                                    <p>${game_details?.totalamount}</p>
                                </div>
                            ) : ""}


                            {game_details?.amount ? (
                                <>
                                    <hr className="border-white/5 border my-5" />
                                    <div className="mt-4 flex justify-between">
                                        <p className="font-bold">Total Amount</p>
                                        <p>${game_details?.amount}</p>
                                    </div>
                                </>
                            ) : null}

                            {
                                payment_status && (
                                    <div className="mt-4 flex justify-between">
                                        <p className="font-bold">Payment Status</p>
                                        {payment_status === "0" ? (
                                            <button
                                                className="text-[#F8924F] py-1 px-4 rounded-md main-border"
                                                style={{ backgroundColor: 'rgba(248, 146, 79, 0.2)' }}
                                            >
                                                Pending
                                            </button>
                                        ) : payment_status === "1" ? (
                                            <button
                                                className="text-[#4AFFA9] py-1 px-4 rounded-md main-border"
                                                style={{ backgroundColor: 'rgba(74, 255, 169, 0.2)' }}
                                            >
                                                Approve
                                            </button>
                                        ) : payment_status === "2" ? (
                                            <button
                                                className="text-[#FF2B62] py-1 px-4 rounded-md main-border"
                                                style={{ backgroundColor: 'rgba(255, 43, 98, 0.2)' }}
                                            >
                                                Cancelled
                                            </button>
                                        ) : payment_status === "3" ? (
                                            <button
                                                className="text-[#FF2B62] py-1 px-4 rounded-md main-border"
                                                style={{ backgroundColor: 'rgba(255, 43, 98, 0.2)' }}
                                            >
                                                Expired
                                            </button>
                                        ) : (<></>)}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default DashboardTransactionView
