import React from 'react'

const Hero = ({ bgImg, title, backgroundColor = "" }) => {
    return (
        <div className='h-[200px] sm:h-[400px] flex justify-center items-center text-[#FFDD15] text-4xl underline bg-cover'
            style={{
                backgroundImage: `url(${bgImg})`,
                backgroundColor
            }}
        >
            <h1 className='font-semibold'>{title}</h1>
        </div>
    )
}

export default Hero