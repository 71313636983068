import React from 'react';
import { Link } from 'react-router-dom';

const ReferralProgram = () => {
    return (
        <div className="flex flex-col min-h-screen bg-[#131A2A] p-4 md:p-8 md:py-36">
            <div className="flex flex-col md:flex-row items-center md:space-x-8 bg-[#1F2937] p-6 sm:p-8 rounded-lg shadow-lg text-white">
                {/* Image Section */}
                <div className="w-full md:w-1/2 flex justify-center mb-6 md:mb-0">
                    <img
                        src='https://ik.imagekit.io/luckycharm/3565_Refer_a_Friend_2024_Hero_Article_Banner_Overlay_600x600px_5203edd9a2.png?updatedAt=1731174403680'
                        alt="Referral Program"
                        className="w-full max-w-xs xs:max-w-sm sm:max-w-md md:max-w-lg"
                    />
                </div>

                {/* Text Section */}
                <div className="w-full md:w-1/2">
                    <h2 className="text-xl xs:text-2xl sm:text-3xl font-bold mb-4">🎉 Unlock Bonus Credits – Share the Luck! 🎉</h2>
                    {/* Attention */}
                    <p className="mb-4 text-lg font-semibold text-yellow-300">Invite Friends, Earn Rewards!</p>

                    {/* Interest */}
                    <p className="mb-4">
                        Simply head to the <span className="font-bold">Profile Tab</span> in your Lucky Charm Sweep account,
                        copy your unique referral code, and share it with your friends to start earning today.
                    </p>

                    {/* Desire */}
                    <p className="mb-4">
                        For each friend who signs up and deposits at least <span className="font-bold">$10</span> using your referral code, 
                        both of you will get <span className="font-bold">£10 in free credits!</span> You can invite up to <span className="font-bold">10 friends </span> 
                        and earn a total of <span className="text-yellow-300 font-bold">£100 plus 10 Free Spins!</span>
                    </p>

                    <p className="mb-4 text-sm text-gray-300 italic">
                        Imagine the possibilities! Use your free credits on our exciting sweepstakes games and keep the wins coming.
                    </p>

                    {/* Action */}
                    <Link
                        to="/user/referrals"
                        className="inline-block bg-purple-600 text-white py-2 px-4 rounded-full text-center hover:bg-purple-700 font-bold mt-4"
                    >
                        Get Your Referral Code →
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ReferralProgram;
