import React from 'react'
import Hero from '../../../components/Hero'
import BlogBg from '../../../assets/image/blogBg.png'
import Details from '../../../components/BlogDetails/Details'
import '../../../components/BlogDetails/blog.css';


const BlogDetails = () => {
    return (
        <>
            <Hero title={"BlogDetails"} bgImg={BlogBg} backgroundColor='#290A47' />
            <Details />
        </>
    )
}

export default BlogDetails
