import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  token: null,
  hotGames: [],
  ourPlatform: [],
  levels: [], // Array to store all the objects
  activeLevel: null, // Object to store the active level
  main_balance: 0,
  bonus_balance: 0,
  total_deposit: 0,
  promotion_count: 0,
  system_count: 0,
  total_count: 0,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userToken: (state, action) => {
      state.token = action.payload.token;
    },
    userData: (state, action) => {
      state.user = action.payload.user;
    },
    HotGameData: (state, action) => {
      state.hotGames = action.payload.hotGames;
    },
    OurPlatformData: (state, action) => {
      state.ourPlatform = action.payload.ourPlatform;
    },
    setLevelDataState: (state, action) => {
      state.levels = action.payload;

      // Set activeLevel by finding the first object with active === true
      state.activeLevel = action.payload.find(item => item.active) || null;
    },
    setActiveLevel(state, action) {
      state.activeLevel = action.payload;
    },
    UPDATE_BALANCE: (state, action) => {
      state.main_balance = parseFloat(action.payload.main_balance); // Ensure conversion to float
      state.bonus_balance = parseFloat(action.payload.bonus_balance); // Ensure conversion to float
      state.total_deposit = parseFloat(action.payload.total_deposit); // Ensure conversion to float
      state.promotion_count = parseFloat(action.payload.promotion_count); // Ensure conversion to float
      state.system_count = parseFloat(action.payload.system_count); // Ensure conversion to float
      state.total_count = parseFloat(action.payload.total_count); // Ensure conversion to float
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.hotGames = [];
      state.ourPlatform = [];
      state.levels = [];
      state.activeLevel = null;
      state.main_balance = 0;
      state.bonus_balance = 0;
      state.total_deposit = 0;
      state.promotion_count = 0;
      state.system_count = 0;
      state.total_count = 0;
    },
  },
});

export const { userToken, userData, HotGameData, OurPlatformData, logout, setLevelDataState, setActiveLevel, UPDATE_BALANCE } = authSlice.actions;
export default authSlice.reducer;
