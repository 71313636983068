import React from 'react'
import BlogBg from '../../../assets/image/blogBg.png'
import Hero from '../../../components/Hero'
import { useParams } from 'react-router-dom'
import MainContent from "./MainContent"

const Platform = () => {
    const { id } = useParams();
    return (
        <>
            <Hero bgImg={BlogBg} title={"Platform"} />
            <MainContent />
        </>
    )
}

export default Platform
